import { Box, Link, styled } from '@mui/material';
import { ReactComponent as Pin } from 'assets/img/icons/pin.svg';
import { GeolocationAutocomplete } from 'components/Forms';
import SelectableMap from 'components/Forms/SelectableMap';
import { ThemeTypography } from 'designSystem';
import { Field } from 'formik';
import React, { FC, useState } from 'react';
import CoordinatesInput from 'designSystem/Formik/CoordinatesInputField/CoordinatesInputField';
import { themeColors } from 'styles/themeColors';

interface ILocationSelectorBlockProps {
  showOnlyCountry?: boolean;
}

const StyledGeoLocationAutocomplete = styled(GeolocationAutocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    minHeight: '30px !important',
    maxHeight: '30px !important',
  },
  '& .MuiAutocomplete-input': {
    padding: '6px !important',
    fontSize: 12,
  },
}));

const LocationSelectorBlock: FC<ILocationSelectorBlockProps> = ({ showOnlyCountry }) => {
  const [mapOpen, setMapOpen] = useState<boolean>(false);

  return (
    <Box>
      <Box display="flex" alignItems="end" mb={0.5}>
        <ThemeTypography variant="BODY_MEDIUM_BOLD">
          {showOnlyCountry ? 'Country' : 'Location'}
          <span style={{ color: themeColors.primary[40] }}>&nbsp;*</span>&nbsp;&nbsp;
        </ThemeTypography>
        {!showOnlyCountry &&
          (mapOpen ? (
            <>
              <ThemeTypography variant="BODY_SMALL">
                Can&apos;t get the right location?&nbsp;
              </ThemeTypography>
              <ThemeTypography variant="BODY_SMALL_BOLD">
                <Link onClick={() => setMapOpen(false)}>Search address instead</Link>
              </ThemeTypography>
            </>
          ) : (
            <>
              <ThemeTypography variant="BODY_SMALL">
                Can&apos;t find the location? &nbsp;
              </ThemeTypography>
              <ThemeTypography variant="BODY_SMALL_BOLD">
                <Link onClick={() => setMapOpen(true)}>Select on map or add coordinates</Link>
              </ThemeTypography>
            </>
          ))}
      </Box>
      <Box>
        {mapOpen ? (
          <Box>
            <Box display="flex" gap={1} alignItems="center" width="100%" mb={1.5} mt={0.5}>
              <Pin width={16} />
              <Field
                component={CoordinatesInput}
                name="locationCoordinates"
                locationNameKey="locationName"
              />
            </Box>
            <Box height={200}>
              <Field
                component={SelectableMap}
                name="locationCoordinates"
                locationNameKey="locationName"
              />
            </Box>
          </Box>
        ) : (
          <Box display="flex" gap={1} alignItems="center">
            <Pin width={16} />
            <Field
              component={StyledGeoLocationAutocomplete}
              fullWidth
              name="locationName"
              coordinatesKey="locationCoordinates"
              variant="outlined"
              showOnlyCountry={showOnlyCountry}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LocationSelectorBlock;
