import { Box, IconButton, InputAdornment, styled } from '@mui/material';
import { Plus, Search } from '@styled-icons/bootstrap';
import { Loader } from 'components/Forms';
import { SearchInput } from 'components/Forms/SearchToolbar';
import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  InfiniteScrollWrapper,
  PageContainer,
} from 'components/Structure';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import { CardIllustration, EmptyStateCard, ThemeButton } from 'designSystem';
import useSites from 'hooks/useSites';
import React, { FC } from 'react';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';
import { SiteOwnershipType } from 'types/sites.types';
import SitesTable from 'components/Sites/SitesTable/SitesTable';
import { SitesTableColumnKeys } from 'components/Sites/SitesTable/sitesHeaderColumns';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

interface ISitesProps {
  typeOfSite: SiteOwnershipType;
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
}));

const Sites: FC<ISitesProps> = ({ typeOfSite }) => {
  const {
    sites,
    loading,
    error,
    searchTerm,
    hasNextPage,
    handleSearchTermChange,
    handleCreateSite,
    handleCreateSiteCluster,
    handleEditSite,
    handleEditSiteCluster,
    handlePageEndReached,
    handleDeleteSite,
    handleDeleteSiteCluster,
  } = useSites({ typeOfSite });
  const { isPartner } = useCompanyPlanContext();

  if (error) return <ErrorState />;

  if (!sites.length && !searchTerm && !loading) {
    return (
      <Box mt={4}>
        <EmptyStateCard
          data-cy="sites-getting-started-card"
          data-cy-button="sites-getting-started-card-button"
          title={`Add sites ${
            typeOfSite === SiteOwnershipType.EXTERNAL ? 'of your partners' : ''
          } to describe locations and to re-use them in different supply chain activities`}
          variant={ItemTypes.LOCATION}
          buttonText="Add site"
          background={BackgroundTypes.WORLD}
          size={AvailableSizes.LARGE}
          renderIllustration={
            <CardIllustration image={IllustrationTypes.LOCATIONS} width={300} right={25} top={0} />
          }
          onCreateItemClick={() => handleCreateSite()}
        />
      </Box>
    );
  }

  return (
    <PageContainer>
      <ActionContainer>
        <SearchInput
          setDebouncedState={handleSearchTermChange}
          className="search-input"
          data-cy="site-search-input"
          delay={500}
          placeholder="Search"
          initialValue={searchTerm}
          inputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {searchTerm === '' ? (
                  <Search size={12} />
                ) : (
                  <CloseButton onClick={() => handleSearchTermChange('')}>
                    <Close size={16} />
                  </CloseButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box ml={1} display="flex" gap={1}>
          <ThemeButton
            size="medium"
            color="WHITE"
            onClick={handleCreateSiteCluster}
            startIcon={<Plus size={20} />}
            data-cy="create-cluster-btn"
          >
            Add origin cluster
          </ThemeButton>
          <ThemeButton
            size="medium"
            color="YELLOW"
            onClick={() => handleCreateSite()}
            startIcon={<Plus size={20} />}
            data-cy="create-site-btn"
          >
            Add site
          </ThemeButton>
        </Box>
      </ActionContainer>
      {loading && <Loader />}
      {sites.length > 0 && !loading && (
        <InfiniteScrollWrapper
          dataLength={sites.length}
          hasMore={hasNextPage}
          next={handlePageEndReached}
        >
          <SitesTable
            sites={sites}
            columns={[
              SitesTableColumnKeys.TITLE,
              ...(isPartner || typeOfSite === SiteOwnershipType.UNASSIGNED
                ? []
                : [SitesTableColumnKeys.PARTNER]),
              SitesTableColumnKeys.CREATED_AT,
              SitesTableColumnKeys.ACTIONS,
            ]}
            onSiteEdit={handleEditSite}
            onSiteClusterEdit={handleEditSiteCluster}
            onSiteDelete={handleDeleteSite}
            onSiteClusterDelete={handleDeleteSiteCluster}
            onCreateSiteOnCluster={handleCreateSite}
          />
        </InfiniteScrollWrapper>
      )}

      {!sites.length && !loading && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Add site',
            onClick: () => handleCreateSite(),
          }}
          skeletonComponent={<PartnerSkeleton title={searchTerm || ''} />}
        />
      )}
    </PageContainer>
  );
};

export default Sites;
