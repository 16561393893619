import RequestCertificateDialog from 'components/Certificates/RequestCertificateDialog';
import ActivitySelectDecisionDialog from 'components/ChainMapping/Dialogs/ActivitySelectDecisionDialog';
import AddEditActivityDialog from 'components/ChainMapping/Dialogs/AddEditActivityDialog';
import AddStepsDialog from 'components/ChainMapping/Dialogs/AddStepsDialog';
import EditComponentChainDialog from 'components/ChainMapping/Dialogs/EditComponentChainDialog';
import EditStepDialog from 'components/ChainMapping/Dialogs/EditStepDialog';
import ImportComponentChainsDialog from 'components/ChainMapping/Dialogs/ImportComponentChainsDialog';
import SelectActivityDialog from 'components/ChainMapping/Dialogs/SelectActivityDialog';
import AddRawMaterialDialog from 'components/ComponentsLibrary/Dialogs/AddRawMaterialDialog';
import CreateEditComponentDialog from 'components/ComponentsLibrary/Dialogs/CreateEditComponentDialog';
import CopyChainDialog from 'components/ComponentsLibrary/Dialogs/CopyChainDialog';
import EditDocumentDialog from 'components/DocumentLibrary/DocumentDialog/EditDocumentDialog';
import { CreateClaimDialog, EditClaimDialog, RequestClaimDialog } from 'components/ImpactClaims';
import PartnerCreateDialog from 'components/Partners/Dialog/PartnerCreateDialog';
import PartnerFormDialog from 'components/Partners/Dialog/PartnerFormDialog';
import PartnerInviteDialog from 'components/Partners/Dialog/PartnerInviteDialog';
import PartnerResendInviteDialog from 'components/Partners/Dialog/PartnerResendInvitationDialog';
import { PartnerRequestDialog, RequestDetailsDialog } from 'components/Partners/PartnerRequests';
import { PublishFinishedDialog, PublishFinishedDialogSlimVersion } from 'components/Product';
import CreateDuplicateEditProductDialog from 'components/Product/Create/CreateDuplicateEditProductDialog';
import AddLanguageDialog from 'components/Product/LanguageSelect/AddLanguageDialog';
import VisibilitySettingsDialog from 'components/Product/VisibilitySettingsDialog/VisibilitySettingsDialog';
import AcceptRequestDialog from 'components/Requests/AcceptRequestDialog';
import AlertDialog from 'designSystem/Overlays/AlertDialog/AlertDialog';
import LanguageSettingDialog from 'components/Product/Overview/ProductLanguageSettings/LanguageSettingDialog';
import HTMLLinkDialog from 'designSystem/Overlays/HTMLLinkDialog/HTMLLinkDialog';
import React, { ComponentProps, FC, PropsWithChildren, createContext, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import ManageRequestDialog from 'components/Requests/ManageRequestDialog';
import UploadRequestDialog from 'components/PartnerIntegration/RequestReplyDialogs/UploadDocumentToRequestDialog';
import DenyRequestDialog from 'components/PartnerIntegration/RequestReplyDialogs/DenyRequestDialog';
import DocumentSelector from 'components/DocumentLibrary/DocumentSelector';
import UploadDocumentDialog from 'components/DocumentLibrary/UploadDocumentDialog';
import ResultImageDialog from 'components/ComplianceGuide/components/Dialogs/ResultImageDialog';
import StartDueDiligenceDialog from 'components/ComplianceGuide/components/Dialogs/StartDueDiligenceDialog';
import SubmitEudrStatementDialog from 'components/ComplianceGuide/components/Dialogs/SubmitEudrStatementDialog';
import EudrStatementDetailsDialog from 'components/ComplianceGuide/components/Dialogs/EudrStatementDetailsDialog';
import FinishChainRequestDialog from 'components/PartnerIntegration/RequestReplyDialogs/FinishChainRequestDialog';
import ReviewChainRequestDialog from 'components/Requests/ReviewChainRequestDialog';
import AddEditSiteDialog from 'components/Sites/AddEditSiteDialog';
import AddEditSiteClusterDialog from 'components/Sites/AddEditSiteClusterDialog';

/**
 * Whenever you add a new dialog, you need to add it to this object
 * Like this the DialogContext can be used to open any dialog and the props are type safe from the component itself
 * If you do not need to pass any props to the dialog, you can just add the key to the DialogPropsTypeOptional type
 * Please keep them sorted alphabetically to make it easier to find them in the list
 * In visual studio code you can do that by marking all -> press CTRL + SHIFT + P -> Type 'sort ascending'
 */
const DIALOGS = {
  ACTIVITY_SELECT_DECISION: ActivitySelectDecisionDialog,
  ADD_EDIT_ACTIVITY: AddEditActivityDialog,
  ADD_EDIT_SITE: AddEditSiteDialog,
  ADD_EDIT_SITE_CLUSTER: AddEditSiteClusterDialog,
  ADD_LANGUAGE: AddLanguageDialog,
  ADD_RAW_MATERIAL: AddRawMaterialDialog,
  ADD_STEPS: AddStepsDialog,
  ALERT: AlertDialog, // This generic dialog can be used to confirm any action like deleting an item
  COPY_CHAIN: CopyChainDialog,
  CREATE_EDIT_COMPONENT: CreateEditComponentDialog,
  CREATE_EDIT_DUPLICATE_PRODUCT: CreateDuplicateEditProductDialog,
  START_DUE_DILIGENCE: StartDueDiligenceDialog,
  CREATE_IMPACT_CLAIM: CreateClaimDialog,
  DENY_REQUEST: DenyRequestDialog,
  EDIT_CHAIN: EditComponentChainDialog,
  EDIT_DOCUMENT: EditDocumentDialog,
  EDIT_IMPACT_CLAIM: EditClaimDialog,
  EDIT_STEP: EditStepDialog,
  FINISH_CHAIN_REQUEST: FinishChainRequestDialog,
  HTML_LINK: HTMLLinkDialog,
  IMPORT_COMPONENT_CHAINS: ImportComponentChainsDialog,
  LANGUAGE_SETTINGS: LanguageSettingDialog,
  MANAGE_REQUEST: ManageRequestDialog,
  PARTNER_CREATE: PartnerCreateDialog,
  PARTNER_FORM: PartnerFormDialog,
  PARTNER_INVITE: PartnerInviteDialog,
  PARTNER_REQUEST: PartnerRequestDialog,
  PARTNER_RESEND_INVITE: PartnerResendInviteDialog,
  PUBLISH_SUCCESS_SLIM_VERSION: PublishFinishedDialogSlimVersion,
  PUBLISH_SUCCESS: PublishFinishedDialog,
  RESULT_IMAGE: ResultImageDialog,
  REQUEST_CERTIFICATION: RequestCertificateDialog,
  REQUEST_DETAILS: RequestDetailsDialog,
  REQUEST_IMPACT_CLAIM: RequestClaimDialog,
  REVIEW_CHAIN_REQUEST: ReviewChainRequestDialog,
  REVIEW_DOCUMENT_REQUEST: AcceptRequestDialog,
  SELECT_DOCUMENTS: DocumentSelector,
  SELECT_EXISTING_ACTIVITY: SelectActivityDialog,
  EUDR_STATEMENT_DETAILS: EudrStatementDetailsDialog,
  SUBMIT_EUDR_STATEMENT: SubmitEudrStatementDialog,
  UPLOAD_DOCUMENTS: UploadDocumentDialog,
  UPLOAD_REQUEST: UploadRequestDialog,
  VISIBILITY_SETTINGS: VisibilitySettingsDialog,
};

/**
 * Dialogs that do not necessary need the props to be passed
 * will use props?: T instead of props: T
 */
export type DialogPropsTypeOptional =
  | 'CREATE_IMPACT_CLAIM'
  | 'PARTNER_CREATE'
  | 'PARTNER_FORM'
  | 'PARTNER_INVITE'
  | 'UPLOAD_DOCUMENTS'
  | 'ADD_RAW_MATERIAL'
  | 'START_DUE_DILIGENCE';

export type DialogTypes = keyof typeof DIALOGS;

type DialogPropsType<T extends DialogTypes> = ComponentProps<(typeof DIALOGS)[T]>;

type DialogArguments<T extends DialogTypes> =
  | {
      type: T;
    } & (T extends DialogPropsTypeOptional
      ? { props?: DialogPropsType<T> }
      : { props: DialogPropsType<T> });

interface IDialogContextValues {
  activeDialogs: DialogArguments<DialogTypes>[];
  openDialog: <T extends DialogTypes>(args: DialogArguments<T>) => void;
  closeDialog: (type?: DialogTypes) => void;
}

const DialogContext = createContext<IDialogContextValues>({
  openDialog: args => {
    // eslint-disable-next-line no-console
    console.warn('unimplemented dialog context', args.type);
  },
  closeDialog: type => {
    // eslint-disable-next-line no-console
    console.warn('unimplemented dialog context', type);
  },
  activeDialogs: [],
});

/**
 * The default dialog props (open, onClose) should not be passed from the openDialog function
 * Ideally they would be omitted in the prop type but the omit is not working with conditional types
 * https://stackoverflow.com/questions/56916532/difference-b-w-only-exclude-and-omit-pick-exclude-typescript
 */
const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeDialogs, setActiveDialogs] = useState<DialogArguments<DialogTypes>[]>([]);

  const onClose = (type?: DialogTypes) => {
    if (type && typeof type === 'string' && type in DIALOGS) {
      setActiveDialogs(curr => curr.filter(dialog => dialog.type !== type));
    } else {
      setActiveDialogs([]);
    }
  };

  /**
   * Dialogs that are already open with the same type will be closed and replaced
   */
  const openDialog: (args: DialogArguments<DialogTypes>) => void = args => {
    setActiveDialogs(previous => [...previous.filter(({ type }) => type !== args.type), args]);
  };

  return (
    <DialogContext.Provider
      value={{
        activeDialogs,
        openDialog,
        closeDialog: onClose,
      }}
    >
      {activeDialogs.map((dialog, index) => {
        if (!dialog || !(dialog.type in DIALOGS) || !DIALOGS[dialog.type]) {
          // eslint-disable-next-line no-console
          console.warn('An error occurred finding the dialog component', dialog?.type);
          return <></>;
        }

        const DialogComponent = DIALOGS[dialog.type] as FC<
          DialogArguments<typeof dialog.type>['props'] & IDefaultDialogProps
        >;
        const props: DialogArguments<typeof dialog.type>['props'] =
          'props' in dialog && dialog.props ? dialog.props : {};

        return (
          <DialogComponent
            key={dialog.type}
            {...props}
            onClose={() => onClose(dialog.type)}
            open // Before -> isLast = activeDialogs.length - 1 === index;
          />
        );
      })}

      {children}
    </DialogContext.Provider>
  );
};

export { DialogProvider };

export default DialogContext;
