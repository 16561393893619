import { gql } from '@apollo/client';
import { SITE_FRAGMENT } from 'graphql/fragments';

export const CREATE_SITE = gql`
  mutation CreateSite($input: CreateSiteInput!, $lang: Language) {
    createSite(input: $input, lang: $lang) {
      site {
        ...siteValues
      }
    }
  }
  ${SITE_FRAGMENT}
`;

export const UPDATE_SITE = gql`
  mutation UpdateSite($id: UUID!, $input: UpdateSiteInput!, $lang: Language) {
    updateSite(id: $id, input: $input, lang: $lang) {
      site {
        ...siteValues
      }
    }
  }
  ${SITE_FRAGMENT}
`;

export const DELETE_SITE = gql`
  mutation DeleteSite($id: UUID!) {
    deleteSite(id: $id) {
      id
    }
  }
`;
