import { gql } from '@apollo/client';
import { SITE_EDGES_FRAGMENT } from 'graphql/fragments/sites';

export const GET_SITES = gql`
  query GetSites($first: Int, $after: String, $filters: SitesFilters) {
    sites(first: $first, after: $after, filters: $filters) {
      edges {
        ...siteValues
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${SITE_EDGES_FRAGMENT}
`;
