import { mergeDefaults } from 'components/Forms/utils';
import { FastField as Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { Switch } from 'formik-mui';
import { ThemeTypography, ThemeButton } from 'designSystem';
import InfoTooltip from 'designSystem/DataDisplay/InfoTooltip/InfoTooltip';
import { tableSettingsSchema } from 'constants/schemas/dataImport';
import { ITableSettings } from 'types/dataImport.types';
import { styled } from '@mui/material/styles';
import { Grid, ButtonGroup, Box } from '@mui/material';

interface ITableSettingsFormProps {
  settings?: ITableSettings;
  onSubmit: (values: ITableSettings) => void;
  onCancel?: () => void;
}

const FormItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
}));

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledTooltip = styled(InfoTooltip)(() => ({
  margin: 0,
}));

const StyledActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(0, 3, 3),
}));

const InnerForm = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(0, 3, 2),
  backgroundColor: theme.custom.themeColors.grayScale[5],
  borderRadius: 4,
  maxWidth: 400,
}));

const TableSettingsForm: FC<ITableSettingsFormProps> = ({ onSubmit, onCancel, settings }) => {
  const initialValues = mergeDefaults(tableSettingsSchema.default(), {
    autoAssignIds: settings?.autoAssignIds,
    coordinatesVersion: settings?.coordinatesVersion,
    farmSizeUnit: settings?.farmSizeUnit,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={tableSettingsSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <InnerForm>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={1}>
                  <FormItem>
                    <ThemeTypography variant="BODY_MEDIUM">
                      Auto-assign unique id for new rows:
                    </ThemeTypography>
                    <Field
                      name="autoAssignIds"
                      fullWidth
                      component={Switch}
                      type="checkbox"
                      label="Auto assign IDs"
                    />
                    <StyledTooltip
                      variant="INFO"
                      text="If you are using id numbers to identify different farms, it is better to use your existing id numbers. If not, you can enable here to assign an id to each new row. This will ensure a unique id for every farm."
                    />
                  </FormItem>
                </Box>
              </Grid>
              <StyledGridItem item xs={12}>
                <FormItem>
                  <ThemeTypography variant="BODY_MEDIUM">
                    Location coordinates version:
                  </ThemeTypography>
                  <StyledButtonGroup>
                    <ThemeButton
                      size="small"
                      className={values.coordinatesVersion === 'latlng' ? 'selected' : ''}
                      onClick={() => setFieldValue('coordinatesVersion', 'latlng')}
                    >
                      Lat, Lng
                    </ThemeButton>
                    <ThemeButton
                      size="small"
                      className={values.coordinatesVersion === 'lnglat' ? 'selected' : ''}
                      onClick={() => setFieldValue('coordinatesVersion', 'lnglat')}
                    >
                      Lng, Lat
                    </ThemeButton>
                  </StyledButtonGroup>
                  <InfoTooltip
                    variant="INFO"
                    text="Make sure that this coordinate format matches that of your data set, to get the right activity. For some standards, the latitude comes first, and sometimes it is the other way around."
                  />
                </FormItem>
              </StyledGridItem>
              <StyledGridItem item xs={12}>
                <FormItem>
                  <ThemeTypography variant="BODY_MEDIUM">Farm size unit:</ThemeTypography>
                  <StyledButtonGroup>
                    <ThemeButton
                      size="small"
                      className={values.farmSizeUnit === 'km2' ? 'selected' : ''}
                      onClick={() => setFieldValue('farmSizeUnit', 'km2')}
                    >
                      Square km
                    </ThemeButton>
                    <ThemeButton
                      size="small"
                      className={values.farmSizeUnit === 'ha' ? 'selected' : ''}
                      onClick={() => setFieldValue('farmSizeUnit', 'ha')}
                    >
                      Hectares
                    </ThemeButton>
                  </StyledButtonGroup>
                </FormItem>
              </StyledGridItem>
            </Grid>
          </InnerForm>
          <StyledActions>
            <ThemeButton onClick={onCancel} color="BLUE_ICE" size="large">
              Cancel
            </ThemeButton>
            <Box ml={2}>
              <ThemeButton color="YELLOW" size="large" type="submit">
                Save settings
              </ThemeButton>
            </Box>
          </StyledActions>
        </Form>
      )}
    </Formik>
  );
};

export default TableSettingsForm;
