import { FieldWrapper } from 'designSystem';
import { useField } from 'formik';
import React, { FC } from 'react';
import CountryAutocomplete from './CountryAutocomplete';

interface ICountrySelectProps {
  name: string;
  size?: 'small' | 'medium';
  label?: string;
  labelVariant?: 'small' | 'medium' | 'large';
  placeholder?: string;
  isRequired?: boolean;
  helperText?: string;
}

const CountrySelectField: FC<ICountrySelectProps> = ({
  name,
  size = 'small',
  label = 'Country of origin',
  labelVariant = 'small',
  isRequired,
  helperText,
}) => {
  const [field, meta, helper] = useField<string | undefined>(name);

  const handleSelectionChange = async (selection: string | null) => {
    await helper.setTouched(true);
    helper.setValue(selection || undefined);
  };

  return (
    <FieldWrapper
      label={label}
      variant={labelVariant}
      required={isRequired}
      tooltip={
        helperText
          ? {
              helperText,
              variant: 'INFO',
            }
          : undefined
      }
    >
      <CountryAutocomplete
        value={field.value}
        size={size}
        errorMessage={meta.error}
        hasError={!!meta.error && meta.touched}
        onCountrySelect={handleSelectionChange}
      />
    </FieldWrapper>
  );
};

export default CountrySelectField;
