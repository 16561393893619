import { Grid } from '@mui/material';
import SitesAutocomplete from 'components/Sites/Forms/SitesAutocomplete';
import { FieldWrapper } from 'designSystem';
import { useField } from 'formik';
import React, { FC } from 'react';
import { IChainActivity } from 'types/chain.types';
import { ISite } from 'types/sites.types';
import { Coordinates } from 'types/types';
import { ShadowedFieldBlock } from './styles';

interface ISiteSelectorBlockProps {
  size?: 'small' | 'medium';
  helperText?: string;
  isRequired?: boolean;
  label?: string;
  labelVariant?: 'small' | 'medium' | 'large';
}

const SiteSelectorBlock: FC<ISiteSelectorBlockProps> = ({
  size = 'medium',
  helperText,
  isRequired,
  label = 'Site',
  labelVariant,
}) => {
  // For the in-between state we will set the value of location input automatically and disable it if the site is selected
  const [, , { setValue: setLocationCoordinatesValue }] = useField<Coordinates | undefined>(
    'activity.locationCoordinates'
  );
  const [, , { setValue: setLocationNameValue }] = useField<string | undefined>('activity.name');

  /**
   *  Set the value of the owner and component fields automatically when a site is selected
   *  They can be changed again by the user if needed, but we want to guide the user to select the correct values
   */
  const [, , { setValue: setOwnerValue }] = useField<IChainActivity['activity']['partner'] | null>(
    'activity.partner'
  );

  const [{ value }, { error }, { setValue, setTouched }] = useField<string | undefined>(
    'activity.siteId'
  );

  const handleSelectionChange = async (selectedSite: ISite | undefined) => {
    await setTouched(true);
    setValue(selectedSite?.id || undefined, true);
    if (selectedSite) {
      setLocationNameValue(selectedSite.locationName);
      setLocationCoordinatesValue(selectedSite.locationCoordinates);
      setOwnerValue(selectedSite.partner);
    }
  };

  return (
    <ShadowedFieldBlock container grid-gap={2}>
      <Grid item xs={12}>
        <FieldWrapper
          label={label}
          variant={labelVariant || size}
          required={isRequired}
          fullWidth
          tooltip={
            helperText
              ? {
                  helperText,
                  variant: 'INFO',
                }
              : undefined
          }
        >
          <SitesAutocomplete
            defaultValue={value}
            errorMessage={error}
            onSiteSelect={handleSelectionChange}
          />
        </FieldWrapper>
      </Grid>
    </ShadowedFieldBlock>
  );
};

export default SiteSelectorBlock;
