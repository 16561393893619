import { Company, User } from 'types/companyTypes';
import { AvailableLanguages, PartnerRequestStatus, RequestStatusLogs } from 'types/enums';
import { GraphQlConnection, Document, Coordinates, MediaItem, CultivatedAreas } from 'types/types';
import { ImageVariant } from './commonTypes';
import { ImpactClaim } from './impactClaimTypes';
import { IRawMaterial } from './component.types';
import { IMinBaseChain } from './chain.types';

export enum PartnerStatus {
  INVITED = 'INVITED',
  NOT_INVITED = 'NOT_INVITED',
  REGISTERED = 'REGISTERED',
}

export enum PartnerTypeEnum {
  'SUPPLIER' = 'SUPPLIER',
  'FARM' = 'FARM',
  'SELF' = 'SELF',
}

export type PartnerType = `${PartnerTypeEnum}`;

export type IPartnersOnSupplyChain = Pick<Partner, 'id' | 'logo' | 'title' | 'status' | 'type'>;

export enum ActivityType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  UNASSIGNED = 'UNASSIGNED',
}

export enum RequestType {
  CHAIN = 'CHAIN',
  DOCUMENT = 'DOCUMENT',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
}

export interface Farm {
  id: string;
  externalId: string;
  size: number;
  // partnerId: string;
  // cultivatedAreas: MultiPolygon;
}

export enum PartnerLogActions {
  CREATED = 'Created',
  UPDATED = 'Updated',
}

interface PartnerLog {
  id: string;
  comment: string;
  userEmail: string;
  user: User;
  companyId: string;
  company: Company;
  timestamp: string;
  action: keyof typeof PartnerLogActions;
}

export interface IActivity {
  id: string;
  title: string;
  description: string;
  name: string; // location name
  locationCoordinates: Coordinates;
  mediaList: MediaItem[];
  partnerId?: string;
  partner?: Partner;
  langs: AvailableLanguages[];
  createdTimestamp: string;
  siteId?: string | null;
  component?: {
    id: string;
    title: string;
    rawMaterials: IRawMaterial[];
    partnerId?: string | null;
    __typename: 'Component';
  };
  cultivatedAreas?: CultivatedAreas;
}

export type Partner = {
  id: string;
  logo?: ImageVariant | null;
  title: string;
  createdTimestamp: string;
  activities: GraphQlConnection<IActivity>;
  impactClaims: GraphQlConnection<ImpactClaim>;
  status: PartnerStatus;
  logs: PartnerLog[];
  latestLog: PartnerLog | null;
  company?: { name: string; id: string; logo: ImageVariant | null; partnerId?: string };
  externalId?: string;
  // This will be required after the first mile backend changes
} & (
  | { type: `${PartnerTypeEnum.SUPPLIER}` | `${PartnerTypeEnum.SELF}` }
  | { type: `${PartnerTypeEnum.FARM}` }
);

export interface PartnerRequestForm {
  requestTitle: string;
  requestMessage: string;
  requestType: keyof typeof RequestType;
  partnerId: string;
  componentId?: string;
}

interface PartnerRequestLog {
  id: string;
  comment: string;
  userEmail: string;
  user: User;
  companyId: string;
  company: Company;
  timestamp: string;
  action: keyof typeof RequestStatusLogs;
}

export interface PartnerRequest {
  id: string;
  requestedByUserId: string;
  partnerId: string;
  documentId?: string;
  requestTitle: string;
  requestMessage: string;
  requestReplyMessage?: string;
  requestStatus: keyof typeof PartnerRequestStatus;
  requestedTimestamp: string;
  requestedToCompany: Company;
  requestedFromCompany: Company;
  user: User;
  partner: Partner;
  document?: Document;
  latestLog: PartnerRequestLog;
  logs: PartnerRequestLog[];
  previouslyUploadedDocuments?: Document[];
  requestType: keyof typeof RequestType;
  componentId?: string;
  chainId?: string;
  chain?: IMinBaseChain;
  questionnaires?: { overallRisk: 'LOW' | 'MEDIUM' | 'HIGH' };
}

export interface UpdatePartnerRequest {
  partnerRequest: PartnerRequest;
}
