import { CellClickedEvent } from 'ag-grid-community';
import { FarmIcon } from 'assets/img/icons';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import React, { memo } from 'react';
import { DataImportCategories, FarmColumns, ITableSettings } from 'types/dataImport.types';
import { ColDefWithValidator } from '../DataImportTable/excelTable.types';
import CropCellEditor from '../DataImportTable/CellEditors/CropCellEditor';
import CropCellRenderer from '../DataImportTable/CellRenderers/CropCellRenderer';
import HeaderColumn from '../DataImportTable/HeaderColumn';
import { createColumnDefinition } from '../utils/dataImport.utils';
import {
  createPositiveNumberValidator,
  validateCoordinates,
  validateCrop,
  validateNotEmpty,
} from '../utils/dataImport.validators';

interface IDataImportCategoryConfig {
  key: DataImportCategories;
  dataLabel: string;
  tab: ITab;
  getColumnDefinitions: (settings: ITableSettings) => ColDefWithValidator<FarmColumns>[];
}

const startEditingCell = (event: CellClickedEvent) => {
  const colId = event.column.getColId();

  if (event.rowIndex !== null && colId) {
    event.api.startEditingCell({
      rowIndex: event.rowIndex,
      colKey: colId,
    });
  }
};

export const DATA_IMPORT_CATEGORY_CONFIG: IDataImportCategoryConfig[] = [
  {
    key: DataImportCategories.Farms,
    tab: {
      key: DataImportCategories.Farms,
      label: 'Farms',
      startIcon: <FarmIcon height={12} />,
    },
    dataLabel: 'farm',
    getColumnDefinitions: settings => [
      createColumnDefinition('farmId', () => <HeaderColumn title="Farm ID" />, validateNotEmpty),
      createColumnDefinition('name', () => <HeaderColumn title="Name" />, validateNotEmpty),
      createColumnDefinition(
        'crop',
        () => <HeaderColumn title="Crop" />,
        validateCrop,
        CropCellRenderer,
        {
          cellEditor: memo(CropCellEditor),
          onCellClicked: startEditingCell,
        }
      ),
      createColumnDefinition(
        'location',
        () => {
          const subtitle = `(${
            settings.coordinatesVersion === 'latlng' ? 'Lat, Lng' : 'Lng, Lat'
          })`;
          return <HeaderColumn title="Location" subtitle={subtitle} />;
        },
        validateCoordinates
      ),
      createColumnDefinition(
        'farmSize',
        () => {
          const subtitle = `(${settings.farmSizeUnit === 'ha' ? 'Hectares' : 'km²'})`;
          return <HeaderColumn title="Farm size" subtitle={subtitle} />;
        },
        createPositiveNumberValidator('Farm size needs to be a number, in e.g. hectares.', true)
      ),
    ],
  },
];
