import { HeaderColumn } from 'types/types';

export enum SitesTableColumnKeys {
  TITLE = 'title',
  PARTNER = 'partner',
  CREATED_AT = 'createdAt',
  ACTIONS = 'actions',
}

export const sitesHeaderColumns: HeaderColumn<SitesTableColumnKeys>[] = [
  { id: SitesTableColumnKeys.TITLE, headerName: 'Title', sortable: false },
  {
    id: SitesTableColumnKeys.PARTNER,
    headerName: 'Owner',
    sortable: false,
  },
  {
    id: SitesTableColumnKeys.CREATED_AT,
    headerName: 'Created at',
    sortable: false,
  },
  { id: SitesTableColumnKeys.ACTIONS, headerName: 'Actions', sortable: false, align: 'left' },
];
