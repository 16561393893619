import { gql } from '@apollo/client';
import { CULTIVATED_AREAS_FRAGMENT, PARTNER_FRAGMENT } from './partner';
import { IMAGE_VARIANT_FRAGMENT } from './media';

// Todo: Research how to use fragments with unions and fix this

export const BASE_SITE_FRAGMENT = gql`
  fragment baseSiteValues on SiteDefaults {
    id
    partnerId
    partner {
      ...partnerValues
    }
    image {
      ...imageVariant
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    siteClusterId
    externalId
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${PARTNER_FRAGMENT}
`;

export const GENERIC_SITE_FRAGMENT = gql`
  fragment genericSiteValues on GenericSite {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const FARM_SITE_FRAGMENT = gql`
  fragment farmValues on Farm {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    size
    numberOfFarmers
    landOwnership
    rawMaterialId
    cultivatedAreas {
      ...cultivatedAreaValues
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export const MINE_SITE_FRAGMENT = gql`
  fragment mineValues on Mine {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const FOREST_SITE_FRAGMENT = gql`
  fragment forestValues on Forest {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const COLLECTION_SITE_FRAGMENT = gql`
  fragment collectionSiteValues on CollectionSite {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const ORIGIN_SITE_FRAGMENT = gql`
  fragment originSiteValues on OriginSite {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const PLANT_SITE_FRAGMENT = gql`
  fragment plantValues on Plant {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const PROCESSING_SITE_FRAGMENT = gql`
  fragment processingSiteValues on ProcessingSite {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const FACTORY_SITE_FRAGMENT = gql`
  fragment factoryValues on Factory {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const MANUFACTURING_SITE_FRAGMENT = gql`
  fragment manufacturingSiteValues on ManufacturingSite {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const WAREHOUSE_SITE_FRAGMENT = gql`
  fragment warehouseValues on Warehouse {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const HANDLING_SITE_FRAGMENT = gql`
  fragment handlingSiteValues on HandlingSite {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const GROCERY_STORE_SITE_FRAGMENT = gql`
  fragment groceryStoreValues on GroceryStore {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const DISTRIBUTION_SITE_FRAGMENT = gql`
  fragment distributionSiteValues on DistributionSite {
    id
    partnerId
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    externalId
  }
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const SITE_WITHOUT_CLUSTER_FRAGMENT = gql`
  fragment siteWithoutClusterValues on Site {
    ... on GenericSite {
      ...genericSiteValues
    }
    ... on Farm {
      ...farmValues
    }
    ... on Mine {
      ...mineValues
    }
    ... on Forest {
      ...forestValues
    }
    ... on CollectionSite {
      ...collectionSiteValues
    }
    ... on OriginSite {
      ...originSiteValues
    }
    ... on Plant {
      ...plantValues
    }
    ... on ProcessingSite {
      ...processingSiteValues
    }
    ... on Factory {
      ...factoryValues
    }
    ... on ManufacturingSite {
      ...manufacturingSiteValues
    }
    ... on Warehouse {
      ...warehouseValues
    }
    ... on HandlingSite {
      ...handlingSiteValues
    }
    ... on GroceryStore {
      ...groceryStoreValues
    }
    ... on DistributionSite {
      ...distributionSiteValues
    }
  }
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
`;

export const SITE_CLUSTER_FRAGMENT = gql`
  fragment siteClusterValues on SiteCluster {
    id
    partner {
      ...partnerValues
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    image {
      ...imageVariant
    }
    sites {
      edges {
        node {
          ... on GenericSite {
            ...genericSiteValues
          }
          ... on Farm {
            ...farmValues
          }
          ... on Mine {
            ...mineValues
          }
          ... on Forest {
            ...forestValues
          }
          ... on CollectionSite {
            ...collectionSiteValues
          }
          ... on OriginSite {
            ...originSiteValues
          }
          ... on Plant {
            ...plantValues
          }
          ... on ProcessingSite {
            ...processingSiteValues
          }
          ... on Factory {
            ...factoryValues
          }
          ... on ManufacturingSite {
            ...manufacturingSiteValues
          }
          ... on Warehouse {
            ...warehouseValues
          }
          ... on HandlingSite {
            ...handlingSiteValues
          }
          ... on GroceryStore {
            ...groceryStoreValues
          }
          ... on DistributionSite {
            ...distributionSiteValues
          }
        }
      }
    }
  }
  ${SITE_WITHOUT_CLUSTER_FRAGMENT}
  ${PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const SITE_EDGES_FRAGMENT = gql`
  fragment siteValues on SiteEdge {
    node {
      ... on GenericSite {
        ...genericSiteValues
      }
      ... on Farm {
        ...farmValues
      }
      ... on Mine {
        ...mineValues
      }
      ... on Forest {
        ...forestValues
      }
      ... on CollectionSite {
        ...collectionSiteValues
      }
      ... on OriginSite {
        ...originSiteValues
      }
      ... on Plant {
        ...plantValues
      }
      ... on ProcessingSite {
        ...processingSiteValues
      }
      ... on Factory {
        ...factoryValues
      }
      ... on ManufacturingSite {
        ...manufacturingSiteValues
      }
      ... on Warehouse {
        ...warehouseValues
      }
      ... on HandlingSite {
        ...handlingSiteValues
      }
      ... on GroceryStore {
        ...groceryStoreValues
      }
      ... on DistributionSite {
        ...distributionSiteValues
      }
      ... on SiteCluster {
        ...siteClusterValues
      }
    }
  }
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;

export const SITE_FRAGMENT = gql`
  fragment siteValues on Site {
    ... on GenericSite {
      ...genericSiteValues
    }
    ... on Farm {
      ...farmValues
    }
    ... on Mine {
      ...mineValues
    }
    ... on Forest {
      ...forestValues
    }
    ... on CollectionSite {
      ...collectionSiteValues
    }
    ... on OriginSite {
      ...originSiteValues
    }
    ... on Plant {
      ...plantValues
    }
    ... on ProcessingSite {
      ...processingSiteValues
    }
    ... on Factory {
      ...factoryValues
    }
    ... on ManufacturingSite {
      ...manufacturingSiteValues
    }
    ... on Warehouse {
      ...warehouseValues
    }
    ... on HandlingSite {
      ...handlingSiteValues
    }
    ... on GroceryStore {
      ...groceryStoreValues
    }
    ... on DistributionSite {
      ...distributionSiteValues
    }
    ... on SiteCluster {
      ...siteClusterValues
    }
  }
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;
