import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { getIn } from 'formik';
import { Autocomplete } from 'formik-mui';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { isDeepEmpty } from 'utils/object.utils';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-inputRoot': {
    padding: 3,
  },
}));

const AsyncAutocomplete = ({
  request,
  queryKey,
  label,
  getOptionSelected,
  getOptionLabel,
  fieldProps,
  resultLimit,
  fetchDelay,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    field: { name, value },
    form: { touched, errors, setFieldValue },
  } = fieldProps;
  const [inputValue, setInputValue] = useState('');

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const fetch = useMemo(
    () =>
      throttle(async (query, callback) => {
        const { data } = await request(query);
        const results = data[queryKey];
        callback(results);
      }, fetchDelay),
    [] // eslint-disable-line
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(!isDeepEmpty(value) ? [value] : []);
      setLoading(false);
      return undefined;
    }

    setLoading(true);
    fetch(inputValue, results => {
      if (active) {
        let newOptions = [];

        if (!isDeepEmpty(value)) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]); // eslint-disable-line

  const filterOptions = createFilterOptions({
    limit: resultLimit,
  });

  return (
    <StyledAutocomplete
      {...fieldProps}
      id="autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      options={options}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setFieldValue(name, newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, options) => (
        <Box {...props} data-cy="autocomplete-option">
          {getOptionLabel(options)}
        </Box>
      )}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={showError && !!fieldError}
          helperText={showError && fieldError && 'This field is required'}
          placeholder={fieldProps.placeholder || 'Start typing'}
          InputProps={{
            ...params.InputProps,
            style: { maxHeight: 40, minHeight: 40 },
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

AsyncAutocomplete.defaultProps = {
  label: 'Please select',
  getOptionSelected: (option, value) => option.title === value.title,
  getOptionLabel: option => option.title,
  resultLimit: null,
  fetchDelay: 500,
};

AsyncAutocomplete.propTypes = {
  request: PropTypes.func.isRequired,
  queryKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
  fieldProps: PropTypes.object.isRequired,
  resultLimit: PropTypes.number,
  fetchDelay: PropTypes.number,
};

export default AsyncAutocomplete;
