import axios from 'axios';
import Config from 'config/Config';

export interface MapboxGeoLocations {
  title: string;
  lat: number;
  lng: number;
}

interface MapboxResolver {
  Query: {
    getPlaces: (
      _: unknown,
      variables: { query: string; showOnlyCountry?: boolean },
      context: { client: { defaultOptions: { config: Config } } }
    ) => Promise<MapboxGeoLocations[]>;
  };
}

const mapboxResolvers: MapboxResolver = {
  Query: {
    getPlaces: async (_, { query, showOnlyCountry }, { client }) => {
      const { config } = client.defaultOptions;
      const urlSafeQuery = encodeURIComponent(query);
      try {
        const { data } = await axios.get<{
          features: { place_name: string; center: [number, number] }[];
          // @ts-ignore // TODO: Fix config and type it properly
        }>(`${config.geocodingURL}/${urlSafeQuery}.json`, {
          params: {
            types: showOnlyCountry ? 'country' : 'country,place,region,district,address',
            access_token: config.mapBoxAccessToken,
          },
        });

        return data.features.map(({ place_name, center }) => ({
          title: place_name,
          lat: center[1],
          lng: center[0],
        }));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throw new Error(error.message);
      }
    },
  },
};

export default mapboxResolvers;
