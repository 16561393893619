import { Theme } from '@mui/material/styles';
import { IconColor, IconSize } from './Icon';

export const ICON_ROOT_PATH = '/iconLibrary/';

export const IconNames = [
  'activities',
  'activity-default',
  'activity-placeholder',
  'activity',
  'analysis',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'assessment-request-add',
  'assessment-request-dismissed',
  'assessment-request-forward',
  'assessment-request-fulfilled',
  'assessment-request-manage',
  'assessment-request-pending',
  'assessment-request-uploaded',
  'assessment-requests-pending',
  'batch',
  'calendar',
  'chain-request-add',
  'chain-request-dismissed',
  'chain-request-forward',
  'chain-request-fulfilled',
  'chain-request-manage',
  'chain-request-pending',
  'chain-request-uploaded',
  'check-filled',
  'check-outlined',
  'check',
  'chevron-down',
  'chevron-up',
  'communication',
  'companies',
  'compliance',
  'component-chain-edit',
  'component-chain-image',
  'component-chain',
  'component',
  'components',
  'copy',
  'cross',
  'cut',
  'delete',
  'document-request-add',
  'document-request-dismissed',
  'document-request-forward',
  'document-request-fulfilled',
  'document-request-manage',
  'document-request-pending',
  'document-request-uploaded',
  'document-requests-pending',
  'document',
  'documents',
  'download',
  'drag-handle',
  'due-diligence-badge',
  'due-diligence-statement-add',
  'due-diligence-statement',
  'edit',
  'error-filled',
  'farm',
  'focus',
  'geo-request-add',
  'geo-request-dismissed',
  'geo-request-forward',
  'geo-request-fulfilled',
  'geo-request-manage',
  'geo-request-pending',
  'geo-request-uploaded',
  'geo-requests-pending',
  'guide',
  'import',
  'info',
  'invite',
  'link',
  'location',
  'locations',
  'lock',
  'map-marker',
  'move-handle',
  'partner',
  'paste',
  'payments',
  'plus',
  'polygon-data',
  'product-edit',
  'product',
  'products',
  'profile-image',
  'profile-square',
  'raw-material',
  're-invite',
  'reply',
  'scale',
  'settings-filled',
  'show',
  'site-cluster-placeholder',
  'site-placeholder',
  'statement-edit',
  'statement-retract',
  'statement',
  'supply-chain-edit',
  'supply-chain',
  'tag-filled',
  'unselected',
  'upload',
  'warning',
  'workers',
] as const;

export const SizeRecord: Record<IconSize, number> = {
  'x-small': 10,
  small: 12,
  medium: 14,
  large: 16,
  'x-large': 28,
  'xx-large': 32,
  'xxx-large': 40,
};

export const getIconColor = (color: IconColor | string, theme: Theme): string => {
  const ColorRecord: Record<IconColor, string> = {
    yellow: theme.palette.primary.main,
    blue: theme.palette.secondary.main,
    'baby-blue': theme.custom.colors.babyBlue,
    'blue-ice': theme.custom.colors.backgroundLight,
    'blue-frost': theme.custom.colors.blueFrost,
    white: theme.palette.common.white,
    black: theme.palette.common.black,
    'gray-20': theme.custom.themeColors.grayScale[20],
    'gray-30': theme.custom.themeColors.grayScale[30],
    'gray-40': theme.custom.themeColors.grayScale[40],
    'gray-60': theme.custom.themeColors.grayScale[60],
    'gray-80': theme.custom.themeColors.grayScale[80],
    'gray-100': theme.custom.themeColors.grayScale[100],
    'almost-black': theme.custom.themeColors.grayScale[100],
    red: theme.palette.error.main,
    'red-80': theme.custom.themeColors.error[80],
    green: theme.palette.success.main,
  };

  if (Object.keys(ColorRecord).includes(color)) {
    return ColorRecord[color as IconColor];
  }
  return color;
};
