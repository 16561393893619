import { gql } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments';
import {
  PARTNER_FRAGMENT,
  PARTNER_REQUEST_FRAGMENT,
  ACTIVITY_FRAGMENT,
  PARTNER_BASE_FRAGMENT,
  BASE_PARTNER_REQUEST_FRAGMENT,
} from 'graphql/fragments/partner';
import { PartnerRequest } from 'types/partner.types';
import { Document, GraphQlConnection } from 'types/types';

export const GET_ALL_PARTNERS = gql`
  query GetPartners(
    $first: Int
    $after: String
    $before: String
    $filters: PartnersFilters
    $orderBy: PartnersOrderBy
  ) {
    partners(first: $first, after: $after, before: $before, filters: $filters, orderBy: $orderBy) {
      edges {
        node {
          ...partnerValues
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const GET_ALL_BASE_PARTNERS = gql`
  query GetPartners(
    $first: Int
    $after: String
    $before: String
    $filters: PartnersFilters
    $orderBy: PartnersOrderBy
  ) {
    partners(first: $first, after: $after, before: $before, filters: $filters, orderBy: $orderBy) {
      edges {
        node {
          ...partnerBaseValues
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PARTNER_BASE_FRAGMENT}
`;

export const GET_BASE_PARTNER = gql`
  query GetPartner($id: UUID!) {
    partner(id: $id) {
      ...partnerBaseValues
    }
  }
  ${PARTNER_BASE_FRAGMENT}
`;

export const GET_PARTNER_ONLY = gql`
  query GetPartner($id: UUID!) {
    partner(id: $id) {
      ...partnerValues
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const GET_PARTNER = gql`
  query GetPartner($id: UUID!, $status: [PartnerRequestStatus], $numberOfDocuments: Int) {
    partner(id: $id) {
      ...partnerValues
    }
    partnerRequestsSent(partnerId: $id, status: $status) {
      edges {
        node {
          ...partnerRequestValues
        }
      }
    }
    partnerDocuments(id: $id, first: $numberOfDocuments) {
      edges {
        node {
          ...documentsValues
        }
      }
    }
  }
  ${PARTNER_FRAGMENT}
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const GET_PARTNER_ACTIVITY = gql`
  query GetActivity($id: UUID!, $lang: Language) {
    activity(id: $id, lang: $lang) {
      ...activityValues
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const GET_PARTNER_ACTIVITIES = gql`
  query GetActivities($lang: Language, $filters: ActivitiesFilters) {
    activities(lang: $lang, filters: $filters) {
      edges {
        node {
          ...activityValues
        }
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export interface GetPartnerUserHomeDataValues {
  documents: GraphQlConnection<Document>;
  partnerRequestsReceived: GraphQlConnection<PartnerRequest>;
}

export const GET_PARTNER_USER_HOME_DATA = gql`
  query GetPartnerUserHomeData($first: Int!, $after: String, $status: [PartnerRequestStatus]) {
    documents(first: $first, after: $after) {
      edges {
        node {
          ...documentsValues
        }
      }
    }
    partnerRequestsReceived(status: $status) {
      edges {
        node {
          ...partnerRequestValues
        }
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${PARTNER_REQUEST_FRAGMENT}
`;

export interface GetPartnerRequestData {
  partnerRequest: PartnerRequest;
}

export const GET_BASE_PARTNER_REQUEST = gql`
  query GetPartnerRequest($id: UUID!) {
    partnerRequest(id: $id) {
      ...basePartnerRequestValues
    }
  }
  ${BASE_PARTNER_REQUEST_FRAGMENT}
`;

export const GET_PARTNER_REQUEST = gql`
  query GetPartnerRequest($id: UUID!) {
    partnerRequest(id: $id) {
      ...partnerRequestValues
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const GET_ALL_PARTNER_REQUESTS = gql`
  query GetPartnerRequests($status: [PartnerRequestStatus], $partnerId: UUID) {
    partnerRequestsReceived(status: $status) {
      count
      edges {
        node {
          ...partnerRequestValues
        }
      }
    }
    partnerRequestsSent(status: $status, partnerId: $partnerId) {
      count
      edges {
        node {
          ...partnerRequestValues
        }
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;
