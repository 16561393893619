import { Box, ButtonGroup, styled } from '@mui/material';
import { Feature, Polygon, area, featureCollection, geometry, polygon } from '@turf/turf';
import { FlexBox } from 'components/Structure';
import { useLogEvent } from 'components/hooks';
import { ThemeButton, ThemeTypography } from 'designSystem';
import JsonEditor from 'designSystem/Inputs/JsonEditor/JsonEditor';
import CustomMap from 'designSystem/Map/CustomMap';
import { transformLatLngArrayToLngLat } from 'designSystem/Map/utils/map.utils';
import { useField } from 'formik';
import { Map } from 'mapbox-gl';
import React, { FC, useEffect, useMemo, useState } from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { CoordinateVersion } from 'types/dataImport.types';
import { MultiPolygon } from 'types/map.types';
import { Coordinates } from 'types/types';
import { v4 as uuid } from 'uuid';

const PLACEHOLDER = [
  [
    [103.231, 2.312],
    [103.24, 3.24],
    [102.4, 3.234],
    [102.234, 2.234],
  ],
  [
    [100.2, 0.2],
    [100.2, 0.8],
    [100.2, 0.2],
  ],
];

const CultivatedAreasSize = styled(Box)<{ 'map-spacing': booleanish }>(
  ({ theme, 'map-spacing': mapSpacing }) => ({
    position: 'absolute',
    background: theme.custom.themeColors.grayScale[20],
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    zIndex: 3,
    right: theme.spacing(mapSpacing === 'true' ? 6 : 2.5),
    top: mapSpacing === 'true' ? 62 : theme.spacing(10),
  })
);

const Container = styled('div')(() => ({
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
}));

const CultivationAreasBlock: FC = () => {
  const { logEvent } = useLogEvent();
  const [ref, size] = useMeasure<HTMLDivElement>();

  /** The MultiPolygon is in lnglat format always */
  const [field, , helper] = useField<MultiPolygon | null>('activity.coordinates');
  const [locationField] = useField<Coordinates | null>('activity.locationCoordinates');

  /** Features are always in LngLat format from MapBox */
  const [features, setFeatures] = useState<Feature<Polygon>[]>(
    field.value?.map(singlePolygon => polygon([singlePolygon], {}, { id: uuid() })) || []
  );
  const [view, setView] = useState<'map' | 'json'>('map');
  const [inputFormat, setInputFormat] = useState<CoordinateVersion>('lnglat');
  const [jsonValue, setJsonValue] = useState<MultiPolygon | undefined>(field.value || undefined);
  const [mapRef, setMapRef] = useState<Map | null>(null);

  /** Used to draw features on map in case the JSON input changed */
  const featuresCollection = useMemo(() => featureCollection(features), [features]);

  /** Size in hectare */
  const currentPolygonSize = useMemo(() => {
    if (!field.value) {
      return 0;
    }
    const formattedMultiPolygons =
      inputFormat === 'lnglat' ? field.value : transformLatLngArrayToLngLat(field.value);

    return (
      Math.round(
        formattedMultiPolygons.reduce(
          (prev, singlePolygon) => prev + area(geometry('Polygon', [singlePolygon])),
          0
        )
      ) / 1000000
    );
  }, [field.value, inputFormat]);

  /**
   * Update the features on the map and value of the cultivation areas correctly when the field value changes
   * @param value the polygon that will be formatted according to the current input format
   */
  useEffect(() => {
    if (view === 'json' && jsonValue) {
      try {
        const formattedMultiPolygons =
          inputFormat === 'lnglat' ? jsonValue : transformLatLngArrayToLngLat(jsonValue);
        const multiPolygons = formattedMultiPolygons?.map(singlePolygon =>
          polygon([singlePolygon], {}, { id: uuid() })
        );
        setFeatures(multiPolygons); // By updating the features the formik field will be updated as well in the useEffect
      } catch (error) {
        console.error('The json in not a valid polygon', error);
      }
    } else if (view === 'json') {
      setFeatures([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFormat, jsonValue]);

  /** Features to plain mutlipolygon type */
  useEffect(() => {
    if (features.length) {
      const multiPolygon = features.map(({ geometry: { coordinates } }) => coordinates).flat();
      helper.setValue(multiPolygon as MultiPolygon);
      if (view === 'map') {
        setJsonValue(multiPolygon as MultiPolygon);
      }
    } else {
      helper.setValue(null);
      if (view === 'map') {
        setJsonValue(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  /**
   * Update the map position when the location field changes
   */
  useEffect(() => {
    // Only update the map if it is shown and there are no features yet
    if (
      view === 'map' &&
      !featuresCollection.features?.length &&
      locationField.value?.lng &&
      locationField.value?.lng &&
      mapRef
    ) {
      mapRef.flyTo({
        center: [locationField.value.lng, locationField.value.lat],
        zoom: 14,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationField, mapRef]);

  const handleDrawCreate = (newFeatures: Feature<Polygon>[]) => {
    logEvent('DRAW_CREATE_POLYGON');
    setFeatures(prev => [...prev, ...newFeatures]);
  };

  const handleDrawUpdate = (updatedFeatures: Feature<Polygon>[]) => {
    logEvent('DRAW_UPDATE_POLYGON');
    setFeatures(prev => {
      updatedFeatures.forEach(updatedFeature => {
        const index = prev.findIndex(feature => feature.id === updatedFeature.id);
        if (index > -1) {
          prev[index] = updatedFeature;
        }
      });
      return [...prev];
    });
  };

  const handleDrawDelete = (deleteFeatures: Feature<Polygon>[]) => {
    logEvent('DRAW_DELETE_POLYGON');
    setFeatures(prev => {
      deleteFeatures.forEach(deleteFeature => {
        const index = prev.findIndex(feature => feature.id === deleteFeature.id);
        if (index > -1) {
          prev.splice(index, 1);
        }
      });
      return [...prev];
    });
  };

  const handleJsonChange = (value: object | null) => {
    logEvent('ENTERED_GEO_JSON_DATA');
    setJsonValue(value as MultiPolygon);
  };

  return (
    <Box width="100%" height="100%" overflow="hidden">
      <Box mb={1}>
        <ButtonGroup>
          <ThemeButton className={view === 'map' ? 'selected' : ''} onClick={() => setView('map')}>
            Drawing tool
          </ThemeButton>

          <ThemeButton
            className={view === 'json' ? 'selected' : ''}
            onClick={() => setView('json')}
          >
            Raw data
          </ThemeButton>
        </ButtonGroup>
      </Box>

      <Container>
        <CultivatedAreasSize map-spacing={Booleanish(view === 'map')}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
            Total area
          </ThemeTypography>
          <ThemeTypography variant="BODY_SMALL">{currentPolygonSize} km²</ThemeTypography>
        </CultivatedAreasSize>

        {view === 'json' ? (
          <Box width="100%" height="calc(100% - 68px)" position="relative">
            <FlexBox marginY={2} justifyContent="space-between">
              <ThemeTypography variant="BODY_MEDIUM">
                Paste and manage multi-polygon data in text field below.
              </ThemeTypography>
              <ButtonGroup>
                <ThemeButton
                  className={inputFormat === 'latlng' ? 'selected' : ''}
                  onClick={() => setInputFormat('latlng')}
                >
                  Lat, Lng
                </ThemeButton>
                <ThemeButton
                  className={inputFormat === 'lnglat' ? 'selected' : ''}
                  onClick={() => setInputFormat('lnglat')}
                >
                  Lng, Lat
                </ThemeButton>
              </ButtonGroup>
            </FlexBox>
            <Box ref={ref} height="calc(100% - 98px)">
              <JsonEditor
                placeholder={PLACEHOLDER}
                value={jsonValue}
                validationFormat="geojson-multi-polygon"
                errorMessage="Invalid GeoJSON multi polygon format - Please use the RFC 7946 standard."
                hintMessage={`This is an example to illustrate the JSON data format. Make sure you have the correct order of coordinates selected. Currently ${
                  inputFormat === 'latlng' ? '[Latitude, Longitude]' : '[Longitude, Latitude]'
                }.`}
                onChange={handleJsonChange}
                minHeight={size.height}
              />
            </Box>
          </Box>
        ) : (
          <Box width="100%" height="calc(100% - 50px)" position="relative">
            <Box marginY={2}>
              <ThemeTypography variant="BODY_MEDIUM">
                Draw polygon shapes below to mark the cultivation area of this farm activity.
              </ThemeTypography>
            </Box>
            <Box height="calc(100% - 44px)">
              <CustomMap
                defaultDrawFeatures={featuresCollection}
                mapStyle="satellite"
                initialViewState={
                  locationField.value?.lat && locationField.value?.lng
                    ? {
                        latitude: locationField.value?.lat,
                        longitude: locationField.value?.lng,
                        zoom: 14,
                      }
                    : undefined
                }
                style={{
                  height: '100%',
                  width: '100%',
                }}
                markers={
                  locationField.value?.lat && locationField.value?.lng
                    ? [[locationField.value?.lng, locationField.value?.lat]]
                    : undefined
                }
                config={{
                  enablePolygonDrawing: true,
                  enableMapStyleToggle: true,
                  enableCenterButton: true,
                }}
                markerOptions={{
                  customIcon: 'map-marker',
                  color: featuresCollection.features.length ? 'baby-blue' : 'yellow',
                }}
                onDrawCreate={handleDrawCreate}
                onDrawUpdate={handleDrawUpdate}
                onDrawDelete={handleDrawDelete}
                onMapLoad={setMapRef}
              />
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default CultivationAreasBlock;
