import { useQuery } from '@apollo/client';
import { useDialog, useLogEvent } from 'components/hooks';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';
import { appQueryParams } from 'constants/appQueryParams';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { GET_BASE_PARTNER } from 'graphql/queries/partners';
import { GET_SITES } from 'graphql/queries/sites';
import { useEffect, useMemo, useState } from 'react';
import { Partner } from 'types/partner.types';
import { IClusterSite, ISite, SiteOwnershipType } from 'types/sites.types';
import { GraphQlConnection } from 'types/types';
import { StringParam, useQueryParam } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphConnections';

interface IUseSitesProps {
  typeOfSite?: SiteOwnershipType;
  // Can be used to skip the useQueryParam search and use the internal search term instead
  skipUseQueryParamSearch?: boolean;
  queryAllSites?: boolean;
}

const useSites = ({ typeOfSite, skipUseQueryParamSearch, queryAllSites }: IUseSitesProps = {}) => {
  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);
  const [internalSearchTerm, setInternalSearchTerm] = useState<string | null>(searchTerm);

  const { isPartner, partnerId } = useCompanyPlanContext();
  const { logEvent } = useLogEvent();
  const { openDialog } = useDialog();
  const { deleteSite } = useSiteMutations();

  const { data, loading, error, fetchMore, refetch } = useQuery<{
    sites: GraphQlConnection<ISite>;
  }>(GET_SITES, {
    variables: {
      ...(internalSearchTerm || typeOfSite
        ? {
            filters: {
              ...(internalSearchTerm ? { searchTerm: internalSearchTerm } : {}),
              ...(typeOfSite ? { ownership: typeOfSite } : {}),
            },
          }
        : {}),
      first: queryAllSites ? null : 15,
    },
  });

  const { data: partnerData } = useQuery<{ partner: Partner }>(GET_BASE_PARTNER, {
    skip: !isPartner && typeOfSite !== SiteOwnershipType.INTERNAL,
    variables: {
      id: partnerId,
    },
  });

  const sites: ISite[] = useMemo(
    () => (data?.sites ? removeGraphConnections(data.sites) : []),
    [data]
  );

  const hasNextPage: boolean = !!data?.sites?.pageInfo?.hasNextPage;

  const endCursor: string | undefined = data?.sites?.pageInfo?.endCursor;

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  // Keep states in sync
  useEffect(() => setInternalSearchTerm(searchTerm), [searchTerm]);

  useEffect(() => {
    if (!sites?.length && hasNextPage) {
      handlePageEndReached();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, hasNextPage]);

  const handleCreateSite = (siteClusterId?: string) => {
    let partner = typeOfSite === SiteOwnershipType.INTERNAL ? partnerData?.partner : undefined;
    if (siteClusterId) {
      const parentSiteCluster = sites.find(site => site.id === siteClusterId);
      if (parentSiteCluster && parentSiteCluster.partner) {
        partner = parentSiteCluster?.partner;
      }
    }
    openDialog({
      type: 'ADD_EDIT_SITE',
      // Deponing on the view we always assign the site to the partner in the partner view. In the client view internal sites we prefill the partnerId with your own partnerId
      props: {
        siteClusterId,
        ...(isPartner
          ? { partner: partnerData?.partner || partner, hidePartnerSelector: true }
          : {
              partner,
            }),
      },
    });
  };

  const handleEditSite = (site: ISite) => {
    openDialog({
      type: 'ADD_EDIT_SITE',
      // Deponing on the view we always assign the site to the partner in the partner view. In the client view internal sites we prefill the partnerId with your own partnerId
      props: {
        site,
        ...(isPartner
          ? { partner: partnerData?.partner, hidePartnerSelector: true }
          : {
              partner: typeOfSite === SiteOwnershipType.INTERNAL ? partnerData?.partner : undefined,
            }),
      },
    });
  };

  const handleCreateSiteCluster = () => {
    openDialog({
      type: 'ADD_EDIT_SITE_CLUSTER',
      props: isPartner
        ? { partner: partnerData?.partner, hidePartnerSelector: true }
        : { partner: typeOfSite === SiteOwnershipType.INTERNAL ? partnerData?.partner : undefined },
    });
  };

  const handleEditSiteCluster = (siteCluster: IClusterSite) => {
    openDialog({
      type: 'ADD_EDIT_SITE_CLUSTER',
      props: {
        siteCluster,
        ...(isPartner
          ? { partner: partnerData?.partner, hidePartnerSelector: true }
          : {
              partner: typeOfSite === SiteOwnershipType.INTERNAL ? partnerData?.partner : undefined,
            }),
      },
    });
  };

  const handleDeleteSite = (siteId: string) => {
    const siteTitle = sites.find(site => site.id === siteId)?.title;
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete site',
        text: 'Are you sure you want to delete this site? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        itemTitle: siteTitle,
        displayCloseButton: true,
        onSubmit: () => {
          deleteSite({ variables: { id: siteId } });
        },
        onCancel: () => undefined,
      },
    });
  };

  const handleDeleteSiteCluster = (siteId: string) => {
    const siteTitle = sites.find(site => site.id === siteId)?.title;
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete site cluster',
        text: 'Are you sure you want to delete this site cluster? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        itemTitle: siteTitle,
        displayCloseButton: true,
        onSubmit: () => {
          deleteSite({ variables: { id: siteId } });
        },
        onCancel: () => undefined,
      },
    });
  };

  const handleSearchTermChange = (searchTerm: string) => {
    logEvent('SITE_SEARCHED', { searchTerm });

    if (skipUseQueryParamSearch) {
      setInternalSearchTerm(searchTerm);
    } else {
      setSearchTerm(searchTerm);
    }
  };

  return {
    sites,
    loading,
    error,
    searchTerm: internalSearchTerm,
    hasNextPage,
    refetch,
    handleSearchTermChange,
    handleCreateSite,
    handleEditSite,
    handleDeleteSite,
    handleCreateSiteCluster,
    handleEditSiteCluster,
    handlePageEndReached,
    handleDeleteSiteCluster,
  };
};

export default useSites;
