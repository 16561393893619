import React, { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material';
import {
  OpacityContainer,
  Title,
  Location,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { FlexBox } from 'components/Structure';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { ThemeTypography } from 'designSystem';
import { IActivity } from 'types/partner.types';
import Image from 'designSystem/DataDisplay/Image/Image';

const Tag = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.colors.lightBorderColor,
  height: 21,
  padding: theme.spacing(1),
}));

interface IActivityItemProps {
  activity: IActivity;
}

const ActivityItem: FC<IActivityItemProps> = ({ activity }) => {
  const { title, name, mediaList, component } = activity;

  // Check if an image exists on activity
  const firstMediaWithImage = mediaList.find(media => media.imageId || media.imageVariantId);

  return (
    <OpacityContainer display="flex" alignItems="center" is-transparent="false">
      <Box marginRight={2} py={0.5}>
        {firstMediaWithImage?.url ? (
          <Image alt={`Logo of ${title}`} image={firstMediaWithImage} height={44} width={44} />
        ) : (
          <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
            <Icon name="activity-placeholder" color="blue-ice" size="xx-large" />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <FlexBox>
          <Title>{title}</Title>
          {component && (
            <>
              <Icon name="arrow-right" size="large" mx={1} />
              <Tag>
                <ThemeTypography variant="LABEL_INPUT" autoOverflow maxWidth={200}>
                  {component?.title}
                </ThemeTypography>
              </Tag>
            </>
          )}
        </FlexBox>
        <Location>{name}</Location>
      </Box>
    </OpacityContainer>
  );
};

export default ActivityItem;
