import { useQuery } from '@apollo/client';
import { Box, Grid, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Edit } from '@styled-icons/boxicons-solid';
import emailSend from 'assets/img/icons/email-send.svg';
import unknownDocument from 'assets/img/icons/unknown-document.svg';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import CultivationFarmArea from 'components/CultivatedFarmArea/CultivationFarmArea';
import PolygonDataCard from 'components/CultivatedFarmArea/PolygonDataCard';
import { Loader } from 'components/Forms';
import ActivitiesTable from 'components/Partners/ActivitiesTable/ActivitiesTable';
import { ActivitiesTableColumnKeys } from 'components/Partners/ActivitiesTable/activitiesHeaderColumns';
import PartnerDocumentOverview from 'components/Partners/PartnerDocuments/PartnerDocumentOverview';
import PartnerInviteCard from 'components/Partners/PartnerInviteCard/PartnerInviteCard';
import PartnerRequestOverview from 'components/Partners/PartnerRequests/PartnerRequestOverview';
import { ErrorState, PageContainer, PageSubTitle, PageTitle } from 'components/Structure';
import FlexBox from 'components/Structure/FlexBox';
import OverviewHeader from 'components/Structure/OverviewHeader/OverviewHeader';
import { OverviewHeaderContainer } from 'components/Structure/OverviewHeader/OverviewHeader.styles';
import { useDialog } from 'components/hooks';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { EmptyStateCard, ImpactClaimsCollection, InfoTooltip, ThemeButton } from 'designSystem';
import { ILogItem } from 'designSystem/DataDisplay/LogItem/LogItem';
import LogSummary from 'designSystem/DataDisplay/LogSummary/LogSummary';
import ConditionalRender from 'designSystem/Layout/CondionalRender/ConditionalRender';
import Icon from 'designSystem/Primitives/Icon/Icon';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { GET_ALL_PARTNERS, GET_PARTNER } from 'graphql/queries';
import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, ItemTypes } from 'types/enums';
import {
  IActivity,
  Partner,
  PartnerRequest,
  PartnerStatus,
  PartnerTypeEnum,
} from 'types/partner.types';
import { RouteParamsWithId } from 'types/router.types';
import { Document, GraphQlConnection } from 'types/types';
import { removeGraphConnections } from 'utils/graphConnections';
import { getPartnerStatusColor, getReadablePartnerStatus } from 'utils/partnerUtils';

export const RequestIcon = styled('img')(() => ({
  width: 18,
}));

const PartnerOverview: FC = () => {
  const { id } = useParams<RouteParamsWithId>();
  const { openDialog } = useDialog();
  const { isFeatureEnabled } = useFeatureFlags();
  const isPartnerInvitationsEnabled: boolean = isFeatureEnabled(FeatureFlag.PARTNER_INVITATIONS);
  const { isPartner } = useCompanyPlanContext();
  const [skipInviteStepCard, setSkipInviteStepCard] = useState(false);
  const { data, loading, error } = useQuery<
    {
      partner: Partner;
      partnerRequestsSent: GraphQlConnection<PartnerRequest>;
      partnerDocuments: GraphQlConnection<Document>;
    },
    {
      id: string;
      status: PartnerRequest['requestStatus'][];
      numberOfDocuments: number;
    }
  >(GET_PARTNER, {
    variables: {
      // If id is undefined, the query will be skipped
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: id!,
      status: ['DENIED', 'PROVIDED', 'REQUESTED'],
      numberOfDocuments: 4,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const { data: subPartnerData, error: subPartnersError } = useQuery<{
    partners: GraphQlConnection<Partner>;
  }>(GET_ALL_PARTNERS, {
    variables: {
      filters: {
        companyId: data?.partner.company?.id,
        partnerType: PartnerTypeEnum.FARM,
        selfType: 'EXCLUDE_SELF',
      },
    },
    skip: !id || !data || data.partner.type !== PartnerTypeEnum.SUPPLIER,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const partner: Partner | undefined = data?.partner;
  const showInvitePartnerNextStep: boolean =
    partner?.status === PartnerStatus.NOT_INVITED &&
    isPartnerInvitationsEnabled &&
    skipInviteStepCard === false &&
    partner.activities?.edges.length === 0 &&
    !isPartner;

  const isAllowedToSendRequests =
    isPartnerInvitationsEnabled && partner?.status === PartnerStatus.REGISTERED && !isPartner;

  const requests = data ? removeGraphConnections(data.partnerRequestsSent) : [];
  const documents = data ? removeGraphConnections(data.partnerDocuments) : [];

  const latestLog: Omit<ILogItem, 'title'> = useMemo(
    () => ({
      date: partner?.latestLog?.timestamp || '',
      logo: partner?.latestLog?.company.logo?.url,
      author: `${partner?.latestLog?.user.firstName} ${partner?.latestLog?.user.lastName}`,
    }),
    [partner]
  );

  if (!data && loading) return <Loader />;
  if (error || !partner || subPartnersError) return <ErrorState action={undefined} />;

  const partnerActivities = removeGraphConnections(partner.activities);
  const subPartnerActivities = subPartnerData?.partners
    ? removeGraphConnections(subPartnerData.partners).flatMap(partner =>
        removeGraphConnections(partner.activities)
      )
    : [];

  const handleAddActivity = () => {
    openDialog({
      type: 'ADD_EDIT_ACTIVITY',
      props: { mode: 'add-activity', partner: partner, hidePartnerSelector: true },
    });
  };

  const handleEditActivity = (
    activity: IActivity,
    openCultivatedAreas?: boolean,
    hidePartnerSelector = true
  ) => {
    openDialog({
      type: 'ADD_EDIT_ACTIVITY',
      props: {
        mode: 'edit-activity',
        activity: {
          ...activity,
          ...(hidePartnerSelector ? { partner } : {}),
        },
        hidePartnerSelector,
        activeTab: openCultivatedAreas ? 'cultivated-areas' : 'communication',
      },
    });
  };

  const handleEditCultivatedArea = (id: string) => {
    let activity: IActivity | undefined;
    if (
      (activity = partnerActivities.find(activity => activity.id === id)) ||
      (activity = subPartnerActivities.find(activity => activity.id === id))
    ) {
      handleEditActivity(activity, true, false);
    } else {
      console.error('Activity not found');
      return;
    }
  };

  return (
    <PageContainer>
      <PageTitle
        autoBackNavigation
        title="Partner overview"
        goBackLabel="All Partners"
        goBackUrl="/partners"
      />
      <Box mb={3} />

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} xl={8}>
          <OverviewHeader image={partner.logo} imageUrl={defaultPartnerImage}>
            <Box display="flex" justifyContent="space-between" flex={1} height="100%">
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                justifyContent="center"
                alignItems="flex-start"
              >
                <FlexBox>
                  <ThemeTypography variant="TITLE_MEDIUM" autoOverflow maxWidth={550}>
                    {partner.title}
                  </ThemeTypography>

                  {partner.externalId && (
                    <FlexBox ml={2}>
                      <ThemeTypography variant="TITLE_EXTRA_SMALL" color="GRAY_40">
                        |
                      </ThemeTypography>
                      <Box mr={1} />
                      <ThemeTypography
                        variant="TITLE_EXTRA_SMALL"
                        autoOverflow
                        maxWidth={200}
                        color="GRAY_40"
                      >
                        {partner.externalId}
                      </ThemeTypography>
                    </FlexBox>
                  )}
                </FlexBox>

                <FlexBox mb={1}>
                  <FlexBox mr={1}>
                    <Icon name="tag-filled" color="gray-80" size="small" mr={1} />
                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                      {partner.type === PartnerTypeEnum.FARM ? 'Farm' : 'Supplier'}
                    </ThemeTypography>
                  </FlexBox>

                  {isPartnerInvitationsEnabled && !isPartner && (
                    <ThemeTypography
                      variant="ITEM_SUBTITLE"
                      color={getPartnerStatusColor(partner.status)}
                    >
                      {getReadablePartnerStatus(partner.status)}
                    </ThemeTypography>
                  )}
                </FlexBox>

                <Box display="flex">
                  <ThemeButton
                    startIcon={<Edit size={14} />}
                    size="large"
                    color="YELLOW"
                    loading={false}
                    onClick={() => {
                      openDialog({
                        type: 'PARTNER_FORM',
                        props: { partner, isPartner },
                      });
                    }}
                  >
                    Edit Partner
                  </ThemeButton>
                  {isPartnerInvitationsEnabled &&
                    !isPartner &&
                    partner.status === PartnerStatus.NOT_INVITED &&
                    !showInvitePartnerNextStep && (
                      <Box ml={1}>
                        <ThemeButton
                          startIcon={
                            <RequestIcon src={unknownDocument} alt="Document with question mark" />
                          }
                          size="large"
                          color="BLUE_ICE"
                          loading={false}
                          onClick={() => {
                            openDialog({
                              type: 'PARTNER_INVITE',
                              props: { existingPartner: partner },
                            });
                          }}
                        >
                          Invite Partner
                        </ThemeButton>
                      </Box>
                    )}
                  {isPartnerInvitationsEnabled &&
                    partner.status === PartnerStatus.INVITED &&
                    !isPartner && (
                      <Box ml={1}>
                        <ThemeButton
                          startIcon={<RequestIcon src={emailSend} alt="Envelop with arrow" />}
                          data-cy="resend-invite-btn"
                          size="large"
                          color="BLUE_ICE"
                          loading={false}
                          onClick={() => {
                            openDialog({
                              type: 'PARTNER_RESEND_INVITE',
                              props: {
                                partnerId: partner.id,
                              },
                            });
                          }}
                        >
                          Re-send invite
                        </ThemeButton>
                      </Box>
                    )}
                  {!isPartner && (
                    <Box ml={2}>
                      <ImpactClaimsCollection
                        impactClaims={partner.impactClaims}
                        searchQuery={partner.title}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </OverviewHeader>
        </Grid>

        <Grid item xs={6} lg={6} xl={4}>
          <PageSubTitle title="History" mb={2} display={{ xl: 'none' }} />
          <OverviewHeaderContainer>
            <LogSummary latestLog={latestLog} createdTimestamp={partner.createdTimestamp} />
          </OverviewHeaderContainer>
        </Grid>

        {partner.activities?.edges.length > 0 ? (
          <Grid item xs={6}>
            <Box position="relative">
              <Box>
                <PageSubTitle title="Partner activities" mb={1}>
                  <ThemeButton
                    onClick={handleAddActivity}
                    color="WHITE"
                    size="small"
                    data-cy="add-activity-button"
                    startIcon={<Plus size={16} />}
                  >
                    Add new
                  </ThemeButton>
                </PageSubTitle>
              </Box>
              <ActivitiesTable
                hideHeader
                columns={[
                  ActivitiesTableColumnKeys.TITLE,
                  ActivitiesTableColumnKeys.CREATED_AT,
                  ActivitiesTableColumnKeys.ACTIONS,
                ]}
                activities={partnerActivities}
                onSelect={handleEditActivity}
                onEdit={handleEditActivity}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={isAllowedToSendRequests || partner.type === PartnerTypeEnum.FARM ? 6 : 12}>
            <PageSubTitle title="Next Steps" mb={2} />
            {showInvitePartnerNextStep ? (
              <PartnerInviteCard partner={partner} setSkipInviteStepCard={setSkipInviteStepCard} />
            ) : (
              <EmptyStateCard
                variant={ItemTypes.LOCATION}
                onCreateItemClick={handleAddActivity}
                buttonText="Add activity"
                title={
                  partner.status === PartnerStatus.INVITED ||
                  partner.status === PartnerStatus.REGISTERED
                    ? 'Give your partner a head start and add the partner activities you know of'
                    : 'Add the activities which belong to this partner, to reuse in your product communication'
                }
                size={AvailableSizes.LARGE}
                background={BackgroundTypes.WORLD}
              />
            )}
          </Grid>
        )}

        {(subPartnerActivities.some(activity => activity.cultivatedAreas?.coordinates) ||
          partnerActivities.some(activity => activity.cultivatedAreas?.coordinates)) && (
          <Grid item xs={6}>
            <Box flex={1} data-cy="cultivated-area" minHeight={400}>
              <PageSubTitle
                title={
                  partner.type === PartnerTypeEnum.SUPPLIER
                    ? 'Origin cultivation areas'
                    : 'Cultivation areas'
                }
                spaceBetween={false}
                mb={2}
              >
                {partner.type === PartnerTypeEnum.SUPPLIER && (
                  <InfoTooltip
                    text="Overview all origin cultivation areas for this partner. This could be locations of sites that this partner own or locations that they source from."
                    size={AvailableSizes.SMALL}
                  />
                )}
              </PageSubTitle>
              <ConditionalRender
                condition={
                  partner.type === PartnerTypeEnum.SUPPLIER &&
                  subPartnerActivities.some(activity => activity.cultivatedAreas?.coordinates)
                }
              >
                {({ True }) => (
                  <True>
                    <CultivationFarmArea
                      showOwner
                      activities={subPartnerActivities}
                      onEditCultivatedAreaClick={handleEditCultivatedArea}
                    />
                  </True>
                )}
              </ConditionalRender>

              {partner.type === PartnerTypeEnum.FARM && (
                <ConditionalRender
                  condition={partnerActivities.some(
                    activity => activity.cultivatedAreas?.coordinates
                  )}
                >
                  {({ False, True }) => (
                    <>
                      <True>
                        <CultivationFarmArea
                          activities={partnerActivities}
                          onEditCultivatedAreaClick={handleEditCultivatedArea}
                        />
                      </True>
                      <False>
                        {partnerActivities.length > 0 ? (
                          <PolygonDataCard
                            activity={partnerActivities[0]}
                            onEditActivity={() => handleEditActivity(partnerActivities[0], true)}
                          />
                        ) : (
                          <EmptyStateCard
                            variant={ItemTypes.MAP_LAYERS}
                            title="Upload or draw polygons for the cultivation area of each activity"
                            showSpark={false}
                            buttonIcon={<Edit size={14} />}
                            buttonText="Add polygon data"
                          />
                        )}
                      </False>
                    </>
                  )}
                </ConditionalRender>
              )}
            </Box>
          </Grid>
        )}
        {isAllowedToSendRequests && (
          <>
            {!!documents.length && (
              <Grid item xs={6}>
                <PartnerDocumentOverview documents={documents} partner={partner} />
              </Grid>
            )}
            <Grid item xs={6}>
              <PartnerRequestOverview requests={requests} partner={partner} />
            </Grid>
          </>
        )}
      </Grid>
    </PageContainer>
  );
};

export default PartnerOverview;
