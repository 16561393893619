import { useMutation } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { CREATE_SITE, DELETE_SITE, UPDATE_SITE } from 'graphql/mutations/sites';
import { ICreateSiteInput, IUpdateSiteInput } from 'graphql/mutations/types/site-mutation.types';
import { AvailableLanguages } from 'types/enums';
import { ISite } from 'types/sites.types';

const useSiteMutations = () => {
  const { setSuccessMessage, setErrorMessage } = useMessages();

  const [createSite] = useMutation<
    { createSite: { site: ISite } },
    { input: ICreateSiteInput; lang?: AvailableLanguages }
  >(CREATE_SITE, {
    onCompleted: () => {
      setSuccessMessage('Site successfully created');
    },
    onError: () => setErrorMessage('There was an error creating your site'),
    refetchQueries: ['GetSites'],
  });

  const [updateSite] = useMutation<
    { updateSite: { site: ISite } },
    { id: string; input: IUpdateSiteInput; lang?: AvailableLanguages }
  >(UPDATE_SITE, {
    onCompleted: () => {
      setSuccessMessage('Site successfully updated');
    },
    onError: () => setErrorMessage('There was an error updating your site'),
    refetchQueries: ['GetSites'],
  });

  const [deleteSite] = useMutation<{ deleteSite: { id: string } }, { id: string }>(DELETE_SITE, {
    onCompleted: () => {
      setSuccessMessage('Site successfully deleted');
    },
    onError: () => setErrorMessage('There was an error deleting your site'),
    refetchQueries: ['GetSites'],
  });

  return {
    createSite,
    updateSite,
    deleteSite,
  };
};

export default useSiteMutations;
