import { HeaderColumn } from 'types/types';

export enum PartnerHeaderColumnEnum {
  TITLE = 'title',
  ACTIVITIES = 'activities',
  PARTNER = 'supplier',
  CREATED_TIMESTAMP = 'createdTimestamp',
  ACTIONS = 'actions',
}

export type PartnerHeaderColumn = keyof typeof PartnerHeaderColumnEnum;

export const getPartnerHeaderColumns = (enabledColumns: PartnerHeaderColumnEnum[]) => {
  const columns: HeaderColumn<PartnerHeaderColumnEnum>[] = [];

  enabledColumns.forEach(column => {
    switch (column) {
      case PartnerHeaderColumnEnum.TITLE:
        columns.push({ id: PartnerHeaderColumnEnum.TITLE, headerName: 'Title', sortable: false });
        break;

      case PartnerHeaderColumnEnum.ACTIVITIES:
        columns.push({
          id: PartnerHeaderColumnEnum.ACTIVITIES,
          headerName: 'Activities',
          sortable: false,
        });
        break;

      case PartnerHeaderColumnEnum.PARTNER:
        columns.push({
          id: PartnerHeaderColumnEnum.PARTNER,
          headerName: 'Owner',
          sortable: false,
        });
        break;

      case PartnerHeaderColumnEnum.CREATED_TIMESTAMP:
        columns.push({
          id: PartnerHeaderColumnEnum.CREATED_TIMESTAMP,
          headerName: 'Created at',
          sortable: true,
        });
        break;

      case PartnerHeaderColumnEnum.ACTIONS:
        columns.push({
          id: PartnerHeaderColumnEnum.ACTIONS,
          headerName: 'Actions',
          sortable: false,
          align: 'right',
        });
        break;
    }
  });

  return columns;
};
