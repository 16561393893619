import React, { FC, useState } from 'react';
import { AccordionDetails, AccordionSummary, Box, styled } from '@mui/material';
import { Payment } from 'types/payment.types';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { ThemeButton, ThemeTypography } from 'designSystem';
import PageSubTitle from 'components/Structure/PageSubTitle';
import { CardContainer } from 'designSystem';
import { useConfig } from 'components/hooks';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import moment from 'moment';

interface IPaymentAccordionProps {
  payment: Payment;
}

const DATE_FORMAT = 'YYYY-MM-DD';

const AmountContainer = styled(Box)<{ active: boolean }>(({ theme, active }) => ({
  borderRadius: 22,
  border: `2px solid ${
    active ? theme.custom.themeColors.accent[100] : theme.custom.themeColors.grayScale[30]
  }`,
  padding: theme.spacing(1.5, 2),
}));

const Currency = styled('span')(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[30],
}));

const ListItemContent = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(-1),
}));

const QuantityDetails = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: 8,
  width: 'fit-content',
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(2.5),
}));

const PaymentAccordion: FC<IPaymentAccordionProps> = ({ payment }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { cardanoBlockchain } = useConfig();

  const handleOnExpand = () => {
    setExpanded(!expanded);
  };

  const handleClickBlockchain = () => {
    window.open(`${cardanoBlockchain}/${payment.blockchainId}`);
  };

  return (
    <Accordion expanded={expanded} onChange={handleOnExpand}>
      <AccordionSummary>
        <Box display="flex" alignItems="center">
          <AmountContainer mr={2} active={expanded}>
            <ThemeTypography variant="BUTTON_LARGE" color={expanded ? 'ALMOST_BLACK' : 'GRAY_60'}>
              {payment.amount} <Currency>₵</Currency>
            </ThemeTypography>
          </AmountContainer>
          <Box display="flex" flexDirection="column">
            <ThemeTypography variant="ITEM_TITLE">
              {payment.paymentType === 'PREMIUM'
                ? 'Premium payment'
                : `${payment.quantity} kg sold`}
            </ThemeTypography>
            <ThemeTypography variant="BODY_MEDIUM" color="GRAY_60">
              by {payment.payee.name}
            </ThemeTypography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {payment.paymentType === 'STANDARD' && (
          <QuantityDetails>
            <ThemeTypography variant="BODY_LARGE" color="GRAY_60">
              <li>
                <ListItemContent>
                  {/* got the conversion rate from the figma design */}
                  {(payment.quantity / 2.5).toFixed(2)} buckets of Cocoa ({payment.quantity} kg of
                  pulp)
                </ListItemContent>
              </li>
            </ThemeTypography>
          </QuantityDetails>
        )}
        <Box>
          <PageSubTitle title="Payment details" />
          <Box mt={1} />
          <CardContainer py={1} px={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Icon name="partner" color="blue-ice" size="xxx-large" />
              <ThemeTypography variant="ITEM_TITLE" color="BLACK">
                {payment.payer.name}
              </ThemeTypography>
            </Box>
          </CardContainer>
          <Box display="flex" my={1.5} gap={1.5}>
            <Icon name="arrow-down" color="gray-30" />
            <ThemeTypography variant="BODY_SMALL" color="GRAY_60">
              {moment(new Date(payment.timestamp)).format(DATE_FORMAT)}
            </ThemeTypography>
          </Box>
          <CardContainer py={1} px={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Icon name="profile-square" color="gray-60" size="xxx-large" />
              <Box display="flex" flexDirection="column">
                <ThemeTypography variant="ITEM_TITLE" color="BLACK">
                  {payment.payee.name}
                </ThemeTypography>
                <ThemeTypography variant="BODY_MEDIUM" color="ALMOST_BLACK">
                  {/* payee is from type union (Worker | Company) and occupation only exist on Worker */}
                  {'occupation' in payment.payee &&
                    payment.payee.occupation?.charAt(0).toUpperCase() +
                      payment.payee.occupation?.slice(1).toLowerCase()}
                </ThemeTypography>
              </Box>
            </Box>
          </CardContainer>
        </Box>
        <Box mt={2.5}>
          <ThemeButton
            startIcon={<Icon name="link" />}
            variant="contained"
            size="large"
            color="BLUE_ICE"
            onClick={handleClickBlockchain}
            fullWidth
          >
            View on blockchain
          </ThemeButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentAccordion;
