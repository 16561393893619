import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  LinearProgress,
  Typography,
  styled,
} from '@mui/material';
import { ReactComponent as CheckCircle } from 'assets/img/components/checkedCircle.svg';
import { ReactComponent as Circle } from 'assets/img/components/circle.svg';
import ComponentChainPlaceholderImage from 'assets/img/components/component-chain-placeholder.svg';
import SupplyChainPlaceholderImage from 'assets/img/supply-chain-placeholder-image.svg';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, Fragment, MouseEvent, useMemo } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { IChainTaskDetails } from 'types/chain.types';
import { ImageVariant } from 'types/commonTypes';

interface IChainTasksDetailsProps {
  chainTasksDetails?: IChainTaskDetails;
  expanded: boolean;
  title: string;
  image?: ImageVariant;
  isSupplyChain?: boolean;
  internalId?: string;
  height?: number;
  hideEdit?: boolean;
  onExpand: (expanded: boolean) => void;
  onEditChain?: () => void;
}

const StyledAccordion = styled(Accordion)<{ height: number }>(({ theme, height }) => ({
  position: 'absolute',
  borderRadius: '6px !important',
  background: '#fff',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: 'none',
  width: '100%',
  '&.Mui-expanded': {
    boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.11)',
    margin: 0,
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: height,
    margin: 0,
  },
  zIndex: 10,
}));

const StyledAccordionSummary = styled(AccordionSummary)<{ height: number }>(({ height }) => ({
  width: '100%',

  '& .MuiAccordionSummary-content': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height,
    margin: 0,
  },

  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Title = styled(Typography)(() => ({
  fontWeight: 900,
  lineHeight: 1,
  fontSize: 16,
}));

const BorderLinearProgress = styled(LinearProgress)<{ fulfilled: booleanish }>(
  ({ theme, fulfilled }) => ({
    borderRadius: 2,
    height: 12,
    width: 100,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),

    '& .MuiLinearProgress-bar': {
      backgroundColor:
        fulfilled === 'true' ? theme.palette.success.main : theme.palette.primary.main,
    },
  })
);

const TaskCompleted = styled(ThemeTypography)(({ theme }) => ({
  textDecoration: 'line-through',
  color: `${theme.custom.themeColors.grayScale[60]} !important`,
}));

const ChainTasksDetails: FC<IChainTasksDetailsProps> = ({
  expanded,
  height = 55,
  chainTasksDetails,
  title,
  image,
  isSupplyChain,
  internalId,
  hideEdit,
  onEditChain,
  onExpand,
}) => {
  const progress = chainTasksDetails
    ? (chainTasksDetails.completed / chainTasksDetails.total) * 100
    : 0;
  const backupImageUrl = isSupplyChain
    ? SupplyChainPlaceholderImage
    : ComponentChainPlaceholderImage;

  const nextTask = useMemo(() => {
    if (!chainTasksDetails) return null;
    return chainTasksDetails.tasks.find(task => !task.done);
  }, [chainTasksDetails]);

  const handleEditClick = (event: MouseEvent) => {
    event.stopPropagation();
    onEditChain?.();
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onClick={() => onExpand(!expanded)}
      height={height}
      data-tut="onboarding_tour_editor_step_by_step_list"
    >
      <StyledAccordionSummary height={height}>
        <Box display="flex" alignItems="center">
          <Image image={image} backupImageUrl={backupImageUrl} width={42} height={42} />
          <Box ml={2} display="flex">
            <Box display="flex" flexDirection="column">
              <FlexBox gap={1}>
                <Title variant="h5">{title}</Title>
                {internalId && (
                  <Box ml={0.8}>
                    <ThemeTypography
                      variant="BODY_MEDIUM"
                      autoOverflow
                      maxWidth={150}
                      color="GRAY_80"
                    >
                      | {internalId}
                    </ThemeTypography>
                  </Box>
                )}
                {!hideEdit && (
                  <IconButton size="small" onClick={handleEditClick}>
                    <Icon name="edit" size="small" color="gray-60" />
                  </IconButton>
                )}
              </FlexBox>
              <Box mt={0.5} display="flex" alignItems="center">
                <BorderLinearProgress
                  value={progress}
                  variant="determinate"
                  fulfilled={Booleanish(progress === 100)}
                />
                <ThemeTypography variant="ITEM_SUBTITLE" color="GRAY_80">
                  {chainTasksDetails?.completed}/{chainTasksDetails?.total} COMPLETED
                </ThemeTypography>
              </Box>
            </Box>
          </Box>
        </Box>
        {progress !== 100 && (
          <Box display="flex" alignItems="center">
            <Box mr={2} display="flex" flexDirection="column" alignItems="flex-end">
              <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_40">
                Do next:
              </ThemeTypography>
              <ThemeTypography variant="BODY_MEDIUM_BOLD">{nextTask?.title}</ThemeTypography>
            </Box>
          </Box>
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box display="flex" flexDirection="column">
          {chainTasksDetails?.tasks.map((task, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              {task.done ? (
                <Fragment>
                  <Box mr={1.5}>
                    <CheckCircle width={12} />
                  </Box>
                  <TaskCompleted variant="BODY_MEDIUM">{task.title}</TaskCompleted>
                </Fragment>
              ) : (
                <Fragment>
                  <Box mr={1.5}>
                    <Circle width={12} />
                  </Box>
                  <ThemeTypography variant="BODY_MEDIUM">{task.title}</ThemeTypography>
                </Fragment>
              )}
            </Box>
          ))}
          <ThemeTypography variant="BODY_MEDIUM" color="GRAY_40">
            We recommend to regularly re-evaluate and confirm your supply chain information
          </ThemeTypography>
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ChainTasksDetails;
