import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { Box, styled } from '@mui/material';
import {
  Feature,
  FeatureCollection,
  Polygon,
  area,
  center,
  featureCollection,
  geometry,
  polygon,
} from '@turf/turf';
import { COLOR_PALETTE } from 'constants/colors';
import { ThemeTypography } from 'designSystem';
import CustomMap, { IMapMarker, IMapPopup } from 'designSystem/Map/CustomMap';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ImageVariant } from 'types/commonTypes';
import { IActivity } from 'types/partner.types';
import { convertHexToRGBarray } from 'utils';
import { v4 as uuid } from 'uuid';
import CultivationFarmAreaInfoPopup from './CultivationFarmAreaInfoPopup';

interface ICultivationFarmAreaProps {
  /**
   * Shows the partner that own the cultivation areas
   */
  showOwner?: boolean;
  activities: IActivity[];
  onEditCultivatedAreaClick: (activityId: string) => void;
}

const CultivatedAreasSize = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.custom.themeColors.grayScale[20],
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  zIndex: 3,
  right: 46,
  top: 10,
}));

export interface IFeatureProperties {
  id: string;
  color: string;
  title: string;
  name: string;
  /** in square km */
  areaSize: number;
  partner?: {
    id: string;
    title: string;
    logo?: ImageVariant | null;
  };
  component?: {
    title: string;
  };
  // Center of this single cultivation area
  polygonCenter: [number, number];
  // Center of the multi polygon all cultivation areas
  multiPolygonCenter?: [number, number];
}

const CultivationFarmArea: FC<ICultivationFarmAreaProps> = ({
  showOwner,
  activities,
  onEditCultivatedAreaClick,
}) => {
  const [infoPopup, setInfoPopup] = useState<IMapPopup>();
  const [mapZoom, setMapZoom] = useState<number>();

  const harvestActivitiesWithAreas: IActivity[] = activities.filter(
    ({ cultivatedAreas }) => cultivatedAreas !== null
  );

  const handleEditCultivatedArea = (id: string) => {
    setInfoPopup(undefined);
    onEditCultivatedAreaClick(id);
  };

  const handleFeatureClick = useCallback(
    (featureProperties: IFeatureProperties) => {
      setInfoPopup(prev => {
        if (prev && prev.id === featureProperties.id) {
          return undefined;
        }
        return {
          id: featureProperties.id,
          coordinate: featureProperties.polygonCenter,
          content: (
            <CultivationFarmAreaInfoPopup
              showOwner={showOwner}
              infoItem={featureProperties}
              onEditClick={() => handleEditCultivatedArea(featureProperties.id)}
            />
          ),
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoPopup, setInfoPopup]
  );

  /**
   * Used to calculate the owner of the cultivation areas and show colors based on the index
   */
  const cultivationAreasOwner = useMemo(
    () => activities.map(({ partner }) => partner?.id),
    [activities]
  );

  /**
   * Returns hex color based on the owner of the cultivation area
   */
  const getCultivationAreaColor: (partnerId?: string) => string = useCallback(
    partnerId => {
      if (!partnerId) {
        return COLOR_PALETTE[0];
      }
      if (partnerId && cultivationAreasOwner.length && cultivationAreasOwner.includes(partnerId)) {
        const colorIndex = cultivationAreasOwner.findIndex(id => id === partnerId);
        return COLOR_PALETTE[colorIndex % COLOR_PALETTE.length];
      }
      return COLOR_PALETTE[0];
    },
    [cultivationAreasOwner]
  );

  /**
   * This feature collections contains all the cultivation areas of the farm as single polygons with their properties
   * To be able to show the info popup when clicking on the cultivation area on a single polygon we need to have the center of each polygon
   * To be able to show centered markers of the one cultivation area we need to have the center of the multi polygon
   */
  const featuresCollection: FeatureCollection<Polygon, IFeatureProperties> = useMemo(() => {
    const features: Feature<Polygon, IFeatureProperties>[] = harvestActivitiesWithAreas
      .map(({ id, cultivatedAreas, name, title, component, partner }) => {
        if (!cultivatedAreas?.coordinates) {
          return [];
        }
        const multiPolygonGeometry = geometry('Polygon', cultivatedAreas.coordinates);
        return cultivatedAreas.coordinates.map(singlePolygon => {
          const polygonGeometry = geometry('Polygon', [singlePolygon]);
          return polygon<IFeatureProperties>(
            [singlePolygon],
            {
              id,
              color: getCultivationAreaColor(partner?.id),
              name,
              title,
              component,
              partner,
              areaSize: Math.round(area(polygonGeometry)) / 1000000,
              multiPolygonCenter: center(multiPolygonGeometry).geometry.coordinates as [
                number,
                number
              ],
              polygonCenter: center(polygonGeometry).geometry.coordinates as [number, number],
            },
            { id: uuid() }
          );
        });
      })
      .flat();
    return featureCollection(features);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  const layer = useMemo(
    () =>
      new GeoJsonLayer<IFeatureProperties>({
        id: 'polygons',
        data: featuresCollection,
        getLineWidth: 4,
        pickable: true,
        getFillColor: feature => convertHexToRGBarray(feature.properties?.color, 100),
        getLineColor: feature => convertHexToRGBarray(feature.properties?.color, 255),
        onClick: info => handleFeatureClick(info.object.properties),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featuresCollection, cultivationAreasOwner]
  );

  /**
   * Markers are only shown when the zoom level is higher than 10 to identify the cultivation areas if the world is zoomed out
   */
  const markers: IMapMarker[] = useMemo(
    () =>
      mapZoom && mapZoom < 12
        ? featuresCollection.features.reduce((prev, feature) => {
            const coordinate = feature.properties.multiPolygonCenter;
            if (!coordinate) {
              return prev;
            }
            // If marker for that cultivation area was already added, don't add it again
            if (prev.findIndex(prevMarker => prevMarker.coordinate === coordinate) !== -1) {
              return prev;
            }
            return [
              ...prev,
              {
                coordinate,
                options: { customIcon: 'map-marker', color: feature.properties.color },
              },
            ];
          }, [] as IMapMarker[])
        : [],
    [featuresCollection, mapZoom]
  );

  const totalAreaSize = useMemo(
    () => Math.round(area(featuresCollection)) / 1000000,
    [featuresCollection]
  );

  return (
    <Box width="100%" height="100%" position="relative" borderRadius={6}>
      <CultivatedAreasSize>
        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
          Total area
        </ThemeTypography>
        <ThemeTypography variant="BODY_SMALL">{totalAreaSize} km²</ThemeTypography>
      </CultivatedAreasSize>
      <CustomMap
        markers={markers}
        layers={[layer]}
        infoPopup={infoPopup}
        mapStyle="satellite"
        style={{
          height: '400px',
          width: '100%',
          borderRadius: 6,
        }}
        config={{
          enableMapStyleToggle: true,
          enableCenterButton: true,
        }}
        onZoom={event => setMapZoom(event.viewState.zoom)}
        onMapLoad={map => setMapZoom(map.getZoom())}
      />
    </Box>
  );
};

export default CultivationFarmArea;
